var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-medication__table editable_table flex flex-col w-full"},[_c('v-data-table',{ref:"medicationsTable",staticClass:"a-table w-full",attrs:{"single-select":"","item-key":_vm.itemKey,"headers":_vm.headers,"items":_vm.tableDataArr,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"footer-props":_vm.footerProps,"item-class":function (item) { return item.active ? 'v-data-table__selected' : ''; },"data-cy":"medicationsList","no-data-text":_vm.emptyText,"no-results-text":_vm.emptyText,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('DatePickerCell',{ref:("startDate-" + (item.medicationId)),attrs:{"max":item.endDate,"edit-active":item.active,"cell-id":item.medicationId,"disabled":_vm.disabled},on:{"edit-row":_vm.toggleEdit},model:{value:(item.startDate),callback:function ($$v) {_vm.$set(item, "startDate", $$v)},expression:"item.startDate"}})]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('DatePickerCell',{ref:("endDate-" + (item.medicationId)),attrs:{"min":item.startDate,"edit-active":item.active,"cell-id":item.medicationId,"disabled":_vm.disabled},on:{"edit-row":_vm.toggleEdit},model:{value:(item.endDate),callback:function ($$v) {_vm.$set(item, "endDate", $$v)},expression:"item.endDate"}})]}},{key:"item.medication",fn:function(ref){
var item = ref.item;
return [_c('EditableCell',{ref:("medication-" + (item.medicationId)),attrs:{"edit-active":item.active,"cell-id":item.medicationId,"disabled":_vm.disabled,"isRequired":true,"label":"Medication","rules":[_vm.required]},on:{"edit-row":_vm.toggleEdit},model:{value:(item.medication),callback:function ($$v) {_vm.$set(item, "medication", $$v)},expression:"item.medication"}})]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [(item.createdByUser)?_c('div',[_vm._v(" "+_vm._s(item.createdByUser.name)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.createdName)+" ")])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDDMMYYYY(item.created))+" ")]}},{key:"item.dose",fn:function(ref){
var item = ref.item;
return [_c('EditableCell',{ref:("dose-" + (item.medicationId)),attrs:{"edit-active":item.active,"cell-id":item.medicationId,"disabled":_vm.disabled,"isRequired":false,"label":"Dose","rules":[_vm.required],"inputType":"string"},on:{"edit-row":_vm.toggleEdit},model:{value:(item.dose),callback:function ($$v) {_vm.$set(item, "dose", $$v)},expression:"item.dose"}})]}},{key:"item.morn",fn:function(ref){
var item = ref.item;
return [_c('EditableCell',{ref:("morn-" + (item.medicationId)),attrs:{"edit-active":item.active,"cell-id":item.medicationId,"disabled":_vm.disabled,"isRequired":false,"label":"Morn","rules":[_vm.required],"inputType":"number"},on:{"edit-row":_vm.toggleEdit},model:{value:(item.morn),callback:function ($$v) {_vm.$set(item, "morn", $$v)},expression:"item.morn"}})]}},{key:"item.noon",fn:function(ref){
var item = ref.item;
return [_c('EditableCell',{ref:("noon-" + (item.medicationId)),attrs:{"edit-active":item.active,"cell-id":item.medicationId,"disabled":_vm.disabled,"isRequired":false,"label":"Noon","rules":[_vm.required],"inputType":"number"},on:{"edit-row":_vm.toggleEdit},model:{value:(item.noon),callback:function ($$v) {_vm.$set(item, "noon", $$v)},expression:"item.noon"}})]}},{key:"item.eve",fn:function(ref){
var item = ref.item;
return [_c('EditableCell',{ref:("eve-" + (item.medicationId)),attrs:{"edit-active":item.active,"cell-id":item.medicationId,"disabled":_vm.disabled,"isRequired":false,"label":"Eve","rules":[_vm.required],"inputType":"number"},on:{"edit-row":_vm.toggleEdit},model:{value:(item.eve),callback:function ($$v) {_vm.$set(item, "eve", $$v)},expression:"item.eve"}})]}},{key:"item.night",fn:function(ref){
var item = ref.item;
return [_c('EditableCell',{ref:("night-" + (item.medicationId)),attrs:{"edit-active":item.active,"cell-id":item.medicationId,"disabled":_vm.disabled,"isRequired":false,"label":"Night","rules":[_vm.required],"inputType":"number"},on:{"edit-row":_vm.toggleEdit},model:{value:(item.night),callback:function ($$v) {_vm.$set(item, "night", $$v)},expression:"item.night"}})]}},(!_vm.disabled)?{key:"item.editRecord",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"editable_table__edit-record ml-2 absolute text-center",class:{ 'active-tr': item.active}},[(!item.active && _vm.isDeletable)?_c('a',{staticClass:"mx-1 bg-red-light",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteHandler(item)}}},[_c('fa-icon',{staticClass:"text-red",attrs:{"icon":"trash-alt","data-cy":"deleteRecord"}})],1):_vm._e(),(item.active)?_c('a',{staticClass:"mr-1 bg-orange-light",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.cancelHandler.apply(null, arguments)}}},[_c('fa-icon',{staticClass:"text-orange",attrs:{"icon":"times"}})],1):_vm._e(),(item.active)?_c('a',{staticClass:"hover:text-primary bg-primary-lightest",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.saveHandler(item)}}},[_c('fa-icon',{staticClass:"text-primary",attrs:{"icon":"check"}})],1):_vm._e()])]}}:null,(!_vm.disabled && _vm.aodwRoles.indexOf(_vm.role.toLowerCase()) === -1)?{key:"footer",fn:function(){return [_c('v-btn',{staticClass:"mt-2 v-btn--flat inline",attrs:{"depressed":"","color":"success"},on:{"click":_vm.addNewMedicationRow}},[_c('fa-icon',{staticClass:"mr-1 group-hover:text-primary-light",attrs:{"icon":"plus"}}),_vm._v(" Add another ")],1)]},proxy:true}:null],null,true),model:{value:(_vm.rowClickHandler),callback:function ($$v) {_vm.rowClickHandler=$$v},expression:"rowClickHandler"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }