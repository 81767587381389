<template>
    <TelephoneContactTable
        :tableData="telephoneContacts"
        :episode="episode"
        :isDeletable="$can('delete', 'telephone_contacts')"
        @telephone-contact-mutated="refreshTelephoneContacts"
    />
</template>

<script>
import camelcaseKeys from 'camelcase-keys'
import TelephoneContactTable from '@/components/telephone-contact/partials/TelephoneContactTable'
import GET_TELEPHONE_CONTACTS_FOR_EPISODE from '@/graphql/queries/getTelephoneContactsForEpisode.gql'
import PhoneHelper from '@/components/shared/mixins/phoneHelper'

export default {
    name: 'TelephoneContact',
    components: { TelephoneContactTable },
    props: {
        episode: {
            type: Object,
            default: () => {}
        }
    },
    apollo: {
        telephoneContacts: {
            query: GET_TELEPHONE_CONTACTS_FOR_EPISODE,
            variables() {
                return {
                    episodeId: Number(this.episode.episodeId),
                }
            },
            skip() {
                if (!this.episode || !this.episode.episodeId) {
                    this.telephoneContacts = []
                    return true
                }
                return false
            },
            result({ data: { telephoneContacts } }) {
                let convertedTelephoneContacts = camelcaseKeys(telephoneContacts, {deep: true})
                this.telephoneContacts = [...convertedTelephoneContacts]
                this.telephoneContacts.forEach(contact => {
                    contact.home = PhoneHelper.parse(contact.home)
                    contact.work = PhoneHelper.parse(contact.work)
                    contact.mobile = PhoneHelper.parse(contact.mobile)
                })
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            }
        }
    },
    data() {
        return {
            telephoneContacts: []
        }
    },
    methods: {
        refreshTelephoneContacts() {
            this.$apollo.queries.telephoneContacts.refetch()
        }
    }
}
</script>
