<template>
    <div class="bg-white-pure l-box-shadow rounded px-4 py-3 mb-5">
        <h4 class="font-display font-semibold text-left text-lg mb-4">Urinalysis/breathalyser Results</h4>
        <AnalysisResultTable
            :tableData="analysisResults"
            :episode="episode"
            :isEditable="$can('edit', 'analysis_result')"
            :isDeletable="$can('delete', 'analysis_result')"
            @analysis-result-mutated="refreshAnalysisResults"
        />
    </div>
</template>

<script>
import camelcaseKeys from 'camelcase-keys'
import AnalysisResultTable from '@/components/analysis-result/partials/AnalysisResultTable'
import GET_ANALYSIS_RESULTS_FOR_EPISODE from '@/graphql/queries/getAnalysisResultsForEpisode.gql'

export default {
    name: 'AnalysisResult',
    components: { AnalysisResultTable },
    props: {
        episode: {
            type: Object,
            default: () => {}
        }
    },
    apollo: {
        analysisResults: {
            query: GET_ANALYSIS_RESULTS_FOR_EPISODE,
            variables() {
                return {
                    episodeId: Number(this.episode.episodeId),
                }
            },
            skip() {
                if (!this.episode || !this.episode.episodeId) {
                    this.analysisResults = []
                    return true
                }
                return false
            },
            result({ data: { analysisResults } }) {
                let convertedAnalysisResults = camelcaseKeys(analysisResults, {deep: true})
                this.analysisResults = [...convertedAnalysisResults]
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            }
        }
    },
    data() {
        return {
            analysisResults: []
        }
    },
    methods: {
        refreshAnalysisResults() {
            this.$apollo.queries.analysisResults.refetch()
        }
    }
}
</script>
