<template>
    <MedicationsTable
        :tableData="medications"
        :episode="episode"
        :isDeletable="$can('delete', 'medications')"
        @medication-mutated="refreshMedications"
    />
</template>

<script>
import camelcaseKeys from 'camelcase-keys'
import MedicationsTable from '@/components/medications/partials/MedicationsTable'
import GET_MEDICATIONS_FOR_EPISODE from '@/graphql/queries/getMedicationsForEpisode.gql'

export default {
    name: 'MedicationsView',
    components: { MedicationsTable },
    props: {
        episode: {
            type: Object,
            default: () => {}
        }
    },
    apollo: {
        medications: {
            query: GET_MEDICATIONS_FOR_EPISODE,
            variables() {
                return {
                    episodeId: Number(this.episode.episodeId),
                }
            },
            skip() {
                if (!this.episode || !this.episode.episodeId) {
                    this.medications = []
                    return true
                }
                return false
            },
            result({ data: { medications } }) {
                let convertedMedications = camelcaseKeys(medications, {deep: true})
                this.medications = [...convertedMedications]
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            }
        }
    },
    data() {
        return {
            medications: []
        }
    },
    methods: {
        refreshMedications() {
            this.$apollo.queries.medications.refetch()
        }
    }
}
</script>
