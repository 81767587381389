<template>
    <PhoneLogTable
        :tableData="phoneLogs"
        :episode="episode"
        :isEditable="$can('edit', 'phone_call_log')"
        :isDeletable="$can('delete', 'phone_call_log')"
        @phone-log-mutated="refreshPhoneLogs"
    />
</template>

<script>
import camelcaseKeys from 'camelcase-keys'
import PhoneLogTable from '@/components/phone-log/partials/PhoneLogTable'
import GET_PHONE_LOGS_FOR_EPISODE from '@/graphql/queries/getPhoneLogsForEpisode.gql'

export default {
    name: 'PhoneLog',
    components: { PhoneLogTable },
    props: {
        episode: {
            type: Object,
            default: () => {}
        }
    },
    apollo: {
        phoneLogs: {
            query: GET_PHONE_LOGS_FOR_EPISODE,
            variables() {
                return {
                    episodeId: Number(this.episode.episodeId),
                }
            },
            skip() {
                if (!this.episode || !this.episode.episodeId) {
                    this.phoneLogs = []
                    return true
                }
                return false
            },
            result({ data: { phoneLogs } }) {
                let convertedPhoneLogs = camelcaseKeys(phoneLogs, {deep: true})
                this.phoneLogs = [...convertedPhoneLogs]
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            }
        }
    },
    data() {
        return {
            phoneLogs: []
        }
    },
    methods: {
        refreshPhoneLogs() {
            this.$apollo.queries.phoneLogs.refetch()
        }
    }
}
</script>
