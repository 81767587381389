<template>
    <router-link
        data-cy="backLink"
        class="mr-4 my-4 flex items-center group uppercase text-primary text-sm text-primary-light hover:text-primary-dark"
        :to="backRoute"
    >
        <fa-icon icon="chevron-left" class="mr-1 w-3 h-auto group-hover:text-primary-dark"/>
        <span class="text-xs font-bold uppercase group-hover:text-primary-dark" v-html="linkText"></span>
    </router-link>
</template>

<script>
export default {
    name: "BackLink",
    props: {
        backRoute: {
            type: Object,
            default: () => {}
        },
        linkText: {
            type: String,
            default: ''
        }
    }
}
</script>
