var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editable_table flex flex-col w-full"},[_c('v-data-table',{staticClass:"col-span-2",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.tableData,"page":_vm.page,"footer-props":_vm.footerProps,"single-select":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.fileName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFileName(item.file))+" ")]}},{key:"item.createAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date)+" ")]}},{key:"item.addedBy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.addedBy.name)+" ")]}},{key:"item.editDocument",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"editable_table__edit-record ml-2 absolute w-full"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.downloadDocument(item)}}},[_c('fa-icon',{staticClass:"text-grey hover:text-dark-blue",attrs:{"icon":"download","data-cy":"downloadDocument"}})],1),(_vm.isDeletable)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteDocument(item)}}},[_c('fa-icon',{staticClass:"text-grey hover:text-dark-blue",attrs:{"icon":"trash-alt","data-cy":"deleteDocument"}})],1):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }