<template>
    <div>
        <component 
            :is="renderForm" 
            :model="formInput" 
            :rules="rules" 
            ref="formInput"
            class="c-editable-cell"
        >
            <el-form-item prop="inputValue">
                <component 
                    :is="renderEditableComponent" 
                    v-show="editActive" 
                    v-model="formInput.textValue"
                    class="p-0 m-0 my-2 input h-2" 
                    hide-details
                    :fetch-suggestions="querySearch"
                    :clearable="true"
                    @select="handleSelect"
                    @change="onCellClick"
                >
                <template #default="{item}">
                    <div>
                        {{item.text}}
                    </div>
                </template>
                </component>
            </el-form-item>
        </component>
        <el-link @click="cellClickHandler" v-show="!editActive"
            class="justify-start py-1 m-0 hover:text-grey-1 text-grey-2 leading-normal border border-transparent border-l-0 border-r-0 border-t-0"
            :v-html="!linkText ? '&nbsp;': ''" :class="{'w-4/5' : !linkText, 'fieldEmpty': !formInput.inputValue}">
            {{ linkText }}
        </el-link>
    </div>
</template>

<script>
export default {
    name: "SelectCell",
    props: {
        value: {
            type: String,
            default: ''
        },
        displayValue: {
            type: String,
            default: ''
        },
        cellId: {
            type: Number,
            default: null
        },
        editableComponent: {
            type: String,
            default: 'el-select'
        },
        editActive: {
            type: Boolean,
            default: null
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        defaultValue: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: 'Enter value'
        },
        items: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            focused: false,
            formInput: { inputValue: '', textValue: '' },
            rules: {
                inputValue: [
                    { validator: this.inputValueValidator, trigger: 'blur' },
                    { validator: this.inputValueValidator, trigger: 'change' },
                ],
            },
        }
    },
    mounted() {
        this.formInput.inputValue = this.value || this.defaultValue
    },
    methods: {
        inputValueValidator(rule, value, cb) {
            if (this.isRequired) {
                if (!value) {
                    cb('Field required')
                } else {
                    cb()
                }
            } else {
                cb()
            }
        },
        cellClickHandler() {
            if (this.disabled) return
            this.formInput.inputValue = ''
            this.focused = true
            this.$emit('edit-row', this.cellId)
        },
        onCellClick(e) {
            console.log('onCellClick', e)
        },
        validate() {
            return this.$refs.formInput.validate()
        },
        handleSelect(item){
            this.formInput.inputValue = item.value
            this.formInput.textValue = item.text
        },
        clearValidate() {
            return this.$refs.formInput.clearValidate()
        },
        querySearch(queryString, cb) {
            const results = queryString ? this.items?.filter(this.createFilter(queryString)) : this.items
            cb(results)
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant?.text?.toLowerCase().indexOf(queryString.toLowerCase()) === 0
                )
            }
        },
    },
    computed: {
        linkText() {
            let text = this.displayValue || this.value
            return text || ''
        },
        renderEditableComponent() {
            if (this.editActive) return this.editableComponent
            return ''
        },
        renderForm() {
            if (this.editActive) return 'el-form'
            return ''
        },
    },
    watch: {
        value() {
            this.formInput.inputValue = this.value
        },
        'formInput.inputValue': function () {
            if (this.editActive) this.$emit('input', this.formInput.inputValue)
        },
    },
}
</script>