<template>
    <div class="c-telephone-contact__table editable_table flex flex-col w-full">
        <v-data-table
            ref="telephoneContactTable"
            single-select
            :item-key="itemKey"
            :headers="headers"
            :items="tableDataArr"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :footer-props="footerProps"
            :item-class="item => item.active ? 'v-data-table__selected' : ''"
            class="a-table w-full"
            data-cy="telephoneContactList"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            v-model="selecetdRows"
        >
<!--            <template class="" v-slot:item.order="{ item }">-->
<!--                <NumberInputCell-->
<!--                    :ref="`order-${item.telephoneContactId}`"-->
<!--                    :edit-active="item.active"-->
<!--                    :cell-id="item.telephoneContactId"-->
<!--                    v-model="item.order"-->
<!--                    :disabled="disabled"-->
<!--                    :isRequired="true"-->
<!--                    label="No"-->
<!--                    :rules="[required]"-->
<!--                    @edit-row="toggleEdit"-->
<!--                />-->
<!--            </template>-->
           <template class="" v-slot:item.name="{ item }">
               <EditableCell
                   :ref="`name-${item.telephoneContactId}`"
                   :edit-active="item.active"
                   :cell-id="item.telephoneContactId"
                   v-model="item.name"
                   :disabled="disabled"
                   :isRequired="true"
                   label="Name"
                   :rules="[required]"
                   @edit-row="toggleEdit"
               />
           </template>
           <template class="" v-slot:item.relationship="{ item }">
               <EditableCell
                   :ref="`relationship-${item.telephoneContactId}`"
                   :edit-active="item.active"
                   :cell-id="item.telephoneContactId"
                   v-model="item.relationship"
                   :disabled="disabled"
                   label="Relationship"
                   :isRequired="true"
                   :rules="[required]"
                   @edit-row="toggleEdit"
               />
            </template>
            <template class="" v-slot:item.home="{ item }">
                <PhoneNumberCell
                    :key="`home-${item.telephoneContactId}`"
                    :ref="`home-${item.telephoneContactId}`"
                    :edit-active="item.active"
                    label="Home"
                    v-model="item.home"
                    :cell-id="item.telephoneContactId"
                    :disabled="disabled"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.work="{ item }">
                <PhoneNumberCell
                    :key="`work-${item.telephoneContactId}`"
                    :ref="`work-${item.telephoneContactId}`"
                    :edit-active="item.active"
                    label="Work"
                    v-model="item.work"
                    :cell-id="item.telephoneContactId"
                    :disabled="disabled"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.mobile="{ item }">
                <PhoneNumberCell
                    :key="`mobile-${item.telephoneContactId}`"
                    :ref="`mobile-${item.telephoneContactId}`"
                    :edit-active="item.active"
                    label="Mobile"
                    v-model="item.mobile"
                    :cell-id="item.telephoneContactId"
                    :disabled="disabled"
                    @edit-row="toggleEdit"
                />
            </template>
            <!-- TODO: when upgrading, need to change below to this: [`item.editRecord`] -->
            <template class="" v-slot:item.editRecord="{ item }" v-if="!disabled">
                <div class="editable_table__edit-record ml-2 absolute text-center" :class="{ 'active-tr': item.active}">
                    <a
                        href="#"
                        v-if="!item.active && isDeletable"
                        @click.prevent="deleteHandler(item)"
                        class="mx-1 bg-red-light"
                    >
                        <fa-icon icon="trash-alt" class="text-red" data-cy="deleteRecord" />
                    </a>
                    <a
                        href="#"
                        v-if="item.active"
                        @click.prevent="cancelHandler"
                        class="mr-1 bg-orange-light"
                    >
                        <fa-icon icon="times" class="text-orange"/>
                    </a>
                    <a
                        href="#"
                        v-if="item.active"
                        @click.prevent="saveHandler(item)"
                        class="hover:text-primary bg-primary-lightest"
                    >
                        <fa-icon icon="check" class="text-primary" />
                    </a>
                </div>
            </template>
            <template v-slot:footer v-if="!disabled">
                <v-btn
                    depressed
                    class="mt-2 v-btn--flat inline"
                    color="success"
                    @click="addNewRow"
                >
                    <fa-icon icon="plus" class="mr-1 group-hover:text-primary-light" /> Add another
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { DataTableHelper, EditableDataTableHelper } from '@/components/shared/mixins/editableDataTableMixin'
import { FormRules } from '@/components/shared/mixins/formMixins'
import EditableCell from '@/components/partials/EditableCell'
import PhoneNumberCell from '@/components/partials/PhoneNumberCell'
import INSERT_TELEPHONE_CONTACT from '@/graphql/mutations/insertTelephoneContact.gql'
import DELETE_TELEPHONE_CONTACT from '@/graphql/mutations/deleteTelephoneContact.gql'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { ContactHelpers } from '@/components/shared/mixins/clientMixins'
import cloneDeep from 'clone-deep'

export default {
    name: 'TelephoneContactTable',
    components: { PhoneNumberCell, EditableCell },
    mixins: [ DataTableHelper, EditableDataTableHelper, FormRules, MessageDialog, ContactHelpers ],
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        episode: {
            type: Object,
            default: () => {}
        },
        isEditable: {
            type: Boolean,
            default: true
        },
        isDeletable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            emptyText: 'No telephone contact found',
            duration: 3000,
            type: '',
            message: '',
            itemsPerPage: 10,
            page: 1,
            itemKey: 'telephoneContactId',
            footerProps: {
                'items-per-page-options': [10, 20, 30, 40]
            },
            headers: [
                // { text: 'No', align: 'left', value: 'order', class: 'order--header w-50' },
                { text: 'Name', align: 'left', value: 'name', class: 'start-date--header' },
                { text: 'Relationship', align: 'left', value: 'relationship', class: 'w-200 relationship--header' },
                { text: 'Home', align: 'left', value: 'home', class: 'w-240 home--header' },
                { text: 'Work', align: 'left', value: 'work', class: 'w-240 work--header' },
                { text: 'Mob', align: 'left', value: 'mobile', class: 'w-240 mobile--header' },
                { text: '', align: 'left', value: 'editRecord', fixed: true},
            ],
            sortBy: [],
            sortDesc: [true],
            refsToCheck: [
                // 'order',
                'name',
                'relationship',
                'home',
                'work',
                'mobile'
            ],
            defaultTelephoneContact: {
                order: null,
                name: null,
                relationship: null,
                home: {
                    countryCode: 'AU',
                    phone: ''
                },
                work: {
                    countryCode: 'AU',
                    phone: ''
                },
                mobile: {
                    countryCode: 'AU',
                    phone: ''
                }
            },
            fieldNames: ['order', 'name', 'relationship', 'home', 'work', 'mobile']
        }
    },
    methods: {
        getDefaultNewObject() {
            return {
                episodeId: this.episode.episodeId,
                userId: this.loggedInUser.userId,
                ...cloneDeep(this.defaultTelephoneContact)
            }
        },
        getDisplayValue(val, displayList, prop = 'label') {
            if (!displayList || displayList.length === 0 || !val) return ''

            const displayValue = displayList.find(t => t.value.toLowerCase() === val.toLowerCase())
            return displayValue != null ? displayValue[prop] : ''
        },
        validateInputs(id) {
            const forms = this.refsToCheck.reduce((arr, curr) => {
                arr.push(this.$refs[`${curr}-${id}`].validate())
                return arr
            }, [])
            return Promise.all(forms)
        },
        saveHandler() {
            this.validateInputs(this.activeRowId).then(values => {
                let isSuccess = true
                values.forEach(value => { if(!value) isSuccess = false })
                if(isSuccess) {
                    // Post back to db
                    // this.mutationCall(this.getActiveRow(this.tableDataArr), false)

                    const data = this.getActiveRow(this.tableDataArr)
                    delete data.updated
                    const idx = this.tableDataArr.findIndex(t => t.telephoneContactId === data.telephoneContactId)

                    if (data && data.typename) delete data.typename
                    if (this.currentNewId && this.currentNewId === this.activeRowId) delete data.telephoneContactId

                    data.mobile = data?.mobile?.phone || ''
                    data.work = data?.work?.phone || ''
                    data.home = data?.home?.phone || ''

                    delete data.active
                    const convertedData = snakecaseKeys(data, {deep: true})
                    this.$apollo.mutate({
                        mutation: INSERT_TELEPHONE_CONTACT,
                        variables: {
                            telephoneContact: convertedData
                        }
                    }).then(({ data: { telephoneContact } }) => {
                        console.log('telephone contact returned', telephoneContact)
                        if (telephoneContact) {
                            const data = {...camelcaseKeys(telephoneContact, {deep: true})}
                            this.mapPhoneFields(data, ['home', 'work', 'mobile'])
                            console.log('datareturned', data)
                            this.tableDataArr[idx] = data
                        }

                        this.saving = false
                        this.message = 'Saved telephone contact item'
                        this.type = 'success'

                        this.activeRowId = null
                        this.currentNewId = null
                        this.activeRowObj = null

                        this.$emit('telephone-contact-mutated')
                    }).catch(error => {
                        this.message = 'Failed saving telephone contact'
                        this.type = 'error'
                    }).finally(() => {
                        this.saving = false
                        data.active = true
                        this.showMessage({ duration: 5000 })
                    })
                }
            }).catch(err => {
                console.error('validation failed: ', err)
            })
        },
        deleteHandler(item) {
            this.clearRowSelection()
            this.tableDataArr = this.tableDataArr.filter(td => {
                if (td.telephoneContactId !== item.telephoneContactId) return td
            })
            this.$apollo.mutate({
                mutation: DELETE_TELEPHONE_CONTACT,
                variables: {
                    telephoneContactId: item.telephoneContactId
                }
            }).then(({ data: { telephoneContact } }) => {
                console.log('telephone contact returned', telephoneContact)

                this.saving = false
                this.message = 'Deleted telephone contact'
                this.type = 'success'

                this.activeRowId = null
                this.currentNewId = null
                this.activeRowObj = null

                this.$emit('telephone-contact-mutated')
            }).catch(error => {
                this.message = 'Failed deleting telephone contact'
                this.type = 'error'
            }).finally(() => {
                this.saving = false
                this.showMessage({ duration: 5000 })
            })
        },
    },
    computed: {
        ...mapState({
            loggedInUser: state => state.app.loggedInUser,
            staticChoices: state => state.app.staticChoices
        }),
    }
}
</script>
