<template>
    <div>
        <BackLink :backRoute="{ name: 'records', params: { clientId: clientId } }" :linkText="episodeLinkText" />

        <component :is="component" :episode="episode" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import GET_EPISODE from '@/graphql/queries/getEpisode.gql'
import camelcaseKeys from 'camelcase-keys'
import { EpisodeHelpers } from '@/components/shared/mixins/episodeMixins'
import { SharedMethods } from '@/components/shared/mixins/sharedMixins'
import snakecaseKeys from 'snakecase-keys'

import RECORD_TYPES from '@/json/recordTypes.json'
import Alerts from '@/components/alert/Alerts'
import ContactLog from './partials/ContactLog'
import Medications from './partials/Medications'
import AnalysisResult from '@/components/analysis-result/AnalysisResult'
import Documents from '@/components/documents/Documents'
import BackLink from '@/components/shared/BackLink'
// import MedicationDispensed from '../medications/MedicationDispensed.vue'

export default {
    name: 'RecordView',
    components: {
        Alerts,
        ContactLog,
        AnalysisResult,
        Documents,
        BackLink,
        Medications
    },
    mixins: [EpisodeHelpers, SharedMethods],
    data() {
        return {
            clientId: this.$route.params.clientId,
            type: this.$route.params.type,
        }
    },
    mounted() {
        if (!this.episode || !this.episode.episodeId) {
            this.$router.push({ name: 'records', params: { clientId: this.clientId } })
        }
    },
    methods: {
    },
    computed: {
        ...mapState({
            episode: state => state.app.currentEpisode,
            siteSections: state => state.app.siteSections,
        }),
        episodeLinkText() {
            return `EPISODE: ${this.getSiteSection(this.episode)} ${'&nbsp;'} ${'&nbsp;'}  ${this.getDateRange(this.episode)}`
        },
        component() {
            switch (this.type) {
                case 'ALERT':
                    return 'Alerts'
                case 'CONTACT_LOG':
                    return 'ContactLog'
                case 'ANALYSIS_RESULT':
                    return 'AnalysisResult'
                case 'DOCUMENT':
                    return 'Documents'
                case 'MEDICATIONS':
                    return 'Medications'
                default:
                    return null
            }
        }
    }
}
</script>
