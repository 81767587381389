<template>
    <div class="editable_table flex flex-col w-full">
        <v-data-table
            item-key="id"
            :headers="headers"
            :items="tableData"
            :page.sync="page"
            :footer-props="footerProps"
            class="col-span-2"
            single-select
        >
            <template class="" v-slot:item.fileName="{ item }">
                {{ getFileName(item.file) }}
            </template>
            <template class="" v-slot:item.createAt="{ item }">
                {{ item.date }}
            </template>
            <template class="" v-slot:item.addedBy="{ item }">
                {{ item.addedBy.name }}
            </template>
            <template class="" v-slot:item.editDocument="{ item }">
                <div class="editable_table__edit-record ml-2 absolute w-full">
                    <a
                        href="#"
                        @click.prevent="downloadDocument(item)"
                    >
                        <fa-icon icon="download" class="text-grey hover:text-dark-blue" data-cy="downloadDocument"/>
                    </a>
                    <a
                        href="#"
                        v-if="isDeletable"
                        @click.prevent="deleteDocument(item)"
                    >
                        <fa-icon icon="trash-alt" class="text-grey hover:text-dark-blue" data-cy="deleteDocument"/>
                    </a>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { format } from 'date-fns'

export default {
    name: 'DocumentTable',
    components: {},
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        isEditable: {
            type: Boolean,
            default: true
        },
        isDeletable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            headers: [
                { text: 'DOCUMENT', align: 'left', value: 'fileName', class: 'name--header' },
                { text: 'DATE', align: 'left', value: 'createAt', class: 'date--header' },
                { text: 'ADDED BY', align: 'left', value: 'addedBy', class: 'added--header' },
                { text: '', align: 'center', value: 'editDocument', fixed: true },
            ],
            page: 1,
            footerProps: {
                'items-per-page-options': [5, 10, 20, 30, 40]
            },
        }
    },
    mounted() {
        this.$root.$on('confirm-delete-document', this.confirmDeleteDocument)
    },
    methods: {
        downloadDocument(item) {
            this.$emit('downloadDocument', item);
        },
        deleteDocument(item) {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', item)
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalConfirmDeleteDocument')
        },
        confirmDeleteDocument(item) {
            this.$emit('deleteDocument', item)
        },
        parseDate(timestamp) {
            return format(new Date(timestamp * 1000), 'd LLL yyyy - h:mma')
        },
        getFileName(path) {
            return path.split('/')[path.split('/').length - 1]
        }
    },
    watch: {
    },
    destroyed() {
        this.$root.$off('confirm-delete-document')
    }
}
</script>
