<template>
    <div class="c-medication__table editable_table flex flex-col w-full">
        <v-data-table
            ref="medicationsTable"
            single-select
            :item-key="itemKey"
            :headers="headers"
            :items="tableDataArr"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :footer-props="footerProps"
            :item-class="item => item.active ? 'v-data-table__selected' : ''"
            class="a-table w-full"
            data-cy="medicationsList"
            :no-data-text="emptyText"
            :no-results-text="emptyText"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            v-model="rowClickHandler"
        >
           <template class="" v-slot:item.startDate="{ item }">
                <DatePickerCell
                    :max="item.endDate"
                    :ref="`startDate-${item.medicationId}`"
                    :edit-active="item.active"
                    :cell-id="item.medicationId"
                    v-model="item.startDate"
                    :disabled="disabled"
                    @edit-row="toggleEdit"
                />
           </template>
           <template class="" v-slot:item.endDate="{ item }">
                <DatePickerCell
                    :min="item.startDate"
                    :ref="`endDate-${item.medicationId}`"
                    :edit-active="item.active"
                    :cell-id="item.medicationId"
                    v-model="item.endDate"
                    :disabled="disabled"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.medication="{ item }">
                <EditableCell
                    :ref="`medication-${item.medicationId}`"
                    :edit-active="item.active"
                    v-model="item.medication"
                    :cell-id="item.medicationId"
                    :disabled="disabled"
                    :isRequired="true"
                    label="Medication"
                    :rules="[required]"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.createdBy="{ item }">
                <div v-if="item.createdByUser">
                    {{item.createdByUser.name}}
                </div>
                <div v-else>
                    {{item.createdName}}
                </div>
            </template>
            <template class="" v-slot:item.created="{ item }">
               {{formatDDMMYYYY(item.created)}}
            </template>

            <template class="" v-slot:item.dose="{ item }">
                <EditableCell
                    :ref="`dose-${item.medicationId}`"
                    :edit-active="item.active"
                    :cell-id="item.medicationId"
                    v-model="item.dose"
                    :disabled="disabled"
                    :isRequired="false"
                    label="Dose"
                    :rules="[required]"
                    inputType="string"
                    @edit-row="toggleEdit"
                />
            </template>

            <template class="" v-slot:item.morn="{ item }">
                <EditableCell
                    :ref="`morn-${item.medicationId}`"
                    :edit-active="item.active"
                    :cell-id="item.medicationId"
                    v-model="item.morn"
                    :disabled="disabled"
                    :isRequired="false"
                    label="Morn"
                    :rules="[required]"
                    inputType="number"
                    @edit-row="toggleEdit"
                />
            </template>

            <template class="" v-slot:item.noon="{ item }">
                <EditableCell
                    :ref="`noon-${item.medicationId}`"
                    :edit-active="item.active"
                    :cell-id="item.medicationId"
                    v-model="item.noon"
                    :disabled="disabled"
                    :isRequired="false"
                    label="Noon"
                    :rules="[required]"
                    inputType="number"
                    @edit-row="toggleEdit"
                />
            </template>

            <template class="" v-slot:item.eve="{ item }">
                <EditableCell
                    :ref="`eve-${item.medicationId}`"
                    :edit-active="item.active"
                    :cell-id="item.medicationId"
                    v-model="item.eve"
                    :disabled="disabled"
                    :isRequired="false"
                    label="Eve"
                    :rules="[required]"
                    inputType="number"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.night="{ item }">
                <EditableCell
                    :ref="`night-${item.medicationId}`"
                    :edit-active="item.active"
                    :cell-id="item.medicationId"
                    v-model="item.night"
                    :disabled="disabled"
                    :isRequired="false"
                    label="Night"
                    :rules="[required]"
                    inputType="number"
                    @edit-row="toggleEdit"
                />
            </template>

            <template class="" v-slot:item.editRecord="{ item }" v-if="!disabled">
                <div class="editable_table__edit-record ml-2 absolute text-center" :class="{ 'active-tr': item.active}">
                    <a
                        href="#"
                        v-if="!item.active && isDeletable"
                        @click.prevent="deleteHandler(item)"
                        class="mx-1 bg-red-light"
                    >
                        <fa-icon icon="trash-alt" class="text-red" data-cy="deleteRecord" />
                    </a>
                    <a
                        href="#"
                        v-if="item.active"
                        @click.prevent="cancelHandler"
                        class="mr-1 bg-orange-light"
                    >
                        <fa-icon icon="times" class="text-orange"/>
                    </a>
                    <a
                        href="#"
                        v-if="item.active"
                        @click.prevent="saveHandler(item)"
                        class="hover:text-primary bg-primary-lightest"
                    >
                        <fa-icon icon="check" class="text-primary" />
                    </a>
                </div>
            </template>
            <template v-slot:footer v-if="!disabled && aodwRoles.indexOf(role.toLowerCase()) === -1" :class="{ 'active-tr': item.active}">
                <v-btn
                    depressed
                    class="mt-2 v-btn--flat inline"
                    color="success"
                    @click="addNewMedicationRow"
                >
                    <fa-icon icon="plus" class="mr-1 group-hover:text-primary-light" /> Add another
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import cloneDeep from 'clone-deep'
import { DataTableHelper, EditableDataTableHelper } from '@/components/shared/mixins/editableDataTableMixin'
import { FormRules } from '@/components/shared/mixins/formMixins'
import EditableCell from '@/components/partials/EditableCell'
import DatePickerCell from '@/components/partials/DatePickerCell'
import INSERT_MEDICATION from '@/graphql/mutations/insertMedication.gql'
import DELETE_MEDICATION from '@/graphql/mutations/deleteMedication.gql'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { ContactHelpers } from '@/components/shared/mixins/clientMixins'
import { format } from 'date-fns'
import { AODW_ROLES } from '@/utils/constants'

export default {
    name: 'MedicationsTable',
    components: { DatePickerCell, EditableCell },
    mixins: [ DataTableHelper, EditableDataTableHelper, FormRules, MessageDialog, ContactHelpers ],
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        episode: {
            type: Object,
            default: () => {}
        },
        isEditable: {
            type: Boolean,
            default: true
        },
        isDeletable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        console.log('kien',this.$auth.user.name)
        return {
            emptyText: 'No medication found',
            tableDataArr: [],
            currentNewId: null,
            activeRowId: null,
            currentRow: null,
            clientId:'',
            duration: 3000,
            type: '',
            message: '',
            itemsPerPage: 10,
            page: 1,
            itemKey: 'medicationId',
            footerProps: {
                'items-per-page-options': [10, 20, 30, 40]
            },
            headers: [
                // { text: 'No', align: 'left', value: 'order', class: 'order--header w-50' },
                { text: 'Start Date', align: 'left', value: 'startDate', class: 'start-date--header' },
                { text: 'Stop Date', align: 'left', value: 'endDate', class: 'w-200 relationship--header' },
                { text: 'Medication', align: 'left', value: 'medication', class: 'w-240 home--header' },
                { text: 'Added By', align: 'left', value:'createdBy',class: 'w-240 work--header' },
                { text: 'Date Added', align: 'left', value: 'created', class: 'w-240 mobile--header' },
                { text: 'Dose', align: 'left', value: 'dose', class: 'w-240 mobile--header' },
                { text: 'Morn', align: 'left', value: 'morn', class: 'w-240 mobile--header' },
                { text: 'Noon', align: 'left', value: 'noon', class: 'w-240 mobile--header' },
                { text: 'Eve', align: 'left', value: 'eve', class: 'w-240 mobile--header' },
                { text: 'Night', align: 'left', value: 'night', class: 'w-240 mobile--header' },
                { text: '', align: 'left', value: 'editRecord', fixed: true},
            ],
            sortBy: [],
            sortDesc: [true],
            refsToCheck: ['medication'],
            defaultMedication: {
                medication: null,
                dose: null,
                morn: null,
                noon: null,
                eve: null,
                night: null,
                startDate: null,
                endDate: null,
                created: format(new Date(), 'yyyy-MM-dd')
            },
            fieldNames: ['medication', 'dose', 'morn', 'noon', 'eve', 'night', 'startDate', 'endDate', 'created'],
            name: '',
            role: '',
            aodwRoles: [...AODW_ROLES]
        }
    },
    methods: {
        getDefaultNewObject() {
            return {
                ...cloneDeep(this.defaultMedication)
            }
        },
        rowClickHandler(obj, e) {
            this.currentRow = obj
            if(this.isDashboard) {
                this.clientId = obj.episode.client.clientId
                this.$router.push({
                    name: 'episodes',
                    params: {clientId: this.clientId},
                }).catch(err => err)
            }
            e.select(true)
        },
        isActiveRowChanged() {
            const fields = ['medication', 'dose', 'morn', 'noon', 'eve', 'night', 'startDate', 'endDate', 'created']
            const changedObj = this.getActiveRow(this.tableDataArr)
            if (!changedObj) return false

            return fields.filter(field => this.activeRowObj[field] != changedObj[field]).length > 0
        },
        toggleEdit(val) {
            if (
                this.activeRowId && Number(this.activeRowId) === Number(val) ||
                this.currentNewId && Number(this.currentNewId) === Number(val) ||
                !this.isEditable
            ) return
            if (this.currentNewId) {
                this.showDiscardMessage(false)
                return false
            } else if (this.activeRowId && this.isActiveRowChanged()) {
                this.showDiscardMessage(true)
                return false
            }
            this.activeRowId = val
            this.activeRowObj = this.getActiveRow(this.tableDataArr)
        },
        addNewMedicationRow() {
            // if we already have a new behaviour Incident, then we stop the function
            if (this.currentNewId) return
            console.log('adding new medication row')

            this.currentNewId = Math.floor(Math.random() * 1000000) + 5000000
            this.activeRowId = this.currentNewId
            const tempMedication = {
                medicationId: this.currentNewId,
                userId: this.loggedInUser?.userId,
                episodeId: this.episode?.episodeId,
                createdName: this.loggedInUser?.name,
                ...this.defaultMedication,
            }
            this.tableDataArr?.push(tempMedication)

            this.page = parseInt((this.tableDataArr.length - 1) / this.itemsPerPage) + 1
        },
        validateInputs(id) {
            const forms = this.refsToCheck?.reduce((arr, curr) => {
                arr.push(this.$refs[`${curr}-${id}`].validate())
                return arr
            }, [])
            return Promise.all(forms)
        },
        saveHandler() {
            this.validateInputs(this.activeRowId).then(values => {
                let isSuccess = true
                values.forEach(value => { if(!value) isSuccess = false })
                if(isSuccess) {

                    const data = this.getActiveRow(this.tableDataArr)
                    const idx = this.tableDataArr.findIndex(t => t.medicationId === data.medicationId)

                    if (data && data.typename) delete data.typename
                    if (this.currentNewId && this.currentNewId === this.activeRowId) delete data.medicationId
                    delete data.updated
                    delete data.createdName
                    delete data.createdBy
                    delete data.active
                    delete data.createdByUser
                    delete data.episode
                    delete data.userId
                    const convertedData = snakecaseKeys(data, {deep: true})
                    this.$apollo.mutate({
                        mutation: INSERT_MEDICATION,
                        variables: {
                            medication: convertedData
                        }
                    }).then(({ data: { medication } }) => {
                        console.log('medication returned', medication)
                        if (medication) {
                            const data = {...camelcaseKeys(medication, {deep: true})}
                            console.log('datareturned', data)
                            this.tableDataArr[idx] = data
                        }

                        this.saving = false
                        this.message = 'Saved medication item'
                        this.type = 'success'

                        this.activeRowId = null
                        this.currentNewId = null
                        this.activeRowObj = null

                        this.$emit('medication-mutated')
                    }).catch(error => {
                        this.message = 'Failed saving medication'
                        this.type = 'error'
                    }).finally(() => {
                        this.saving = false
                        data.active = true
                        this.showMessage({ duration: 5000 })
                    })
                }
            }).catch(err => {
                console.error('validation failed: ', err)
            })
        },
        resetTableRow() {
            if(!this.currentNewId) {
                this.tableDataArr = this.tableDataArr.map(item => {
                    if (item.medicationId === this.activeRowId) {
                        return this.activeRowObj
                    }
                    return item
                })
            } else {
                this.tableDataArr = this.tableDataArr.filter(item => {
                    if (item.medicationId !== this.activeRowId) return item
                })
            }
        },
        clearRowSelection() {
            this.activeRowId = null
            this.currentNewId = null
            this.activeRowObj = null
        },
        cancelHandler() {
            this.resetTableRow()
            this.clearRowSelection()
        },
        filterTable(data) {
            return data.map(item => {
                let isActive = item.medicationId === this.activeRowId
                return {
                    ...item,
                    active: isActive
                }
            })
        },
        deleteHandler(item) {
            this.clearRowSelection()
            this.tableDataArr = this.tableDataArr.filter(td => {
                if (td.medicationId !== item.medicationId) return td
            })
            this.$apollo.mutate({
                mutation: DELETE_MEDICATION,
                variables: {
                    medicationId: item.medicationId
                }
            }).then(({ data: { medication } }) => {
                console.log('medication returned', medication)

                this.saving = false
                this.message = 'Deleted medication'
                this.type = 'success'

                this.activeRowId = null
                this.currentNewId = null
                this.activeRowObj = null

                this.$emit('medication-mutated')
            }).catch(error => {
                this.message = 'Failed deleting medication'
                this.type = 'error'
            }).finally(() => {
                this.saving = false
                this.showMessage({ duration: 5000 })
            })
        },
    },
    mounted() {
        this.name = this.$auth.user.name,
        this.role = this.$auth.role
        this.tableDataArr = [...this.tableData]
    },
    computed: {
        ...mapState({
            loggedInUser: state => state.app.loggedInUser,
            staticChoices: state => state.app.staticChoices
        }),
        isDashboard() {
            return this.source === 'dashboard'
        }
    },
    watch: {
        tableData() {
            this.clearRowSelection()
            this.tableDataArr = [...this.tableData]
        },
        activeRowId() {
            this.tableDataArr = this.filterTable(this.tableDataArr)
        }
    }
}
</script>
