var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-alert__table editable_table flex flex-col w-full"},[_c('v-data-table',{ref:"filterTable",staticClass:"a-table w-full attach-table relative",attrs:{"single-select":"","item-key":"id","headers":_vm.headers,"items":_vm.tableDataArr,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"footer-props":_vm.footerProps,"data-cy":"alertList","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"no-data-text":_vm.emptyText,"no-results-text":_vm.emptyText},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.rowClickHandler},scopedSlots:_vm._u([{key:"item.dispensedDate",fn:function(ref){
var item = ref.item;
return [_c('DatePickerCell',{ref:("dispensedDate-" + (item.medicationDispensedId)),attrs:{"edit-active":item.active,"label":"Date","cell-id":item.medicationDispensedId,"attachClass":'.attach-table',"disabled":_vm.disabled},on:{"edit-row":_vm.toggleEdit},model:{value:(item.dispensedDate),callback:function ($$v) {_vm.$set(item, "dispensedDate", $$v)},expression:"item.dispensedDate"}})]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('DatetimePickerCell',{ref:("time-" + (item.medicationDispensedId)),attrs:{"edit-active":item.active,"cell-id":item.medicationDispensedId,"disabled":_vm.disabled,"label":"00:00 AM","isRequired":true,"type":"time","format":_vm.timeFormat,"rules":[_vm.required]},on:{"edit-row":_vm.toggleEdit},model:{value:(item.time),callback:function ($$v) {_vm.$set(item, "time", $$v)},expression:"item.time"}})]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [(item.createdByUser)?_c('div',[_vm._v(" "+_vm._s(item.createdByUser.name)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.createdName)+" ")])]}},{key:"item.medication",fn:function(ref){
var item = ref.item;
return [_c('EditableCell',{ref:("medication-" + (item.medicationDispensedId)),attrs:{"edit-active":item.active,"cell-id":item.medicationDispensedId,"disabled":_vm.disabled,"label":"Medication"},on:{"edit-row":_vm.toggleEdit},model:{value:(item.medication),callback:function ($$v) {_vm.$set(item, "medication", $$v)},expression:"item.medication"}})]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('NumberInputCell',{ref:("number-" + (item.medicationDispensedId)),attrs:{"edit-active":item.active,"cell-id":item.medicationDispensedId,"disabled":_vm.disabled,"isRequired":true,"label":"No.","rules":[_vm.required]},on:{"edit-row":_vm.toggleEdit},model:{value:(item.number),callback:function ($$v) {_vm.$set(item, "number", $$v)},expression:"item.number"}})]}},{key:"item.strength",fn:function(ref){
var item = ref.item;
return [_c('NumberInputCell',{ref:("strength-" + (item.medicationDispensedId)),attrs:{"edit-active":item.active,"cell-id":item.medicationDispensedId,"disabled":_vm.disabled,"isRequired":true,"label":"Dose.","rules":[_vm.required]},on:{"edit-row":_vm.toggleEdit},model:{value:(item.strength),callback:function ($$v) {_vm.$set(item, "strength", $$v)},expression:"item.strength"}})]}},{key:"item.dispensedBy",fn:function(ref){
var item = ref.item;
return [(!_vm.isDashboard)?_c('SelectCell',{ref:("dispensedBy-" + (item.medicationDispensedId)),attrs:{"edit-active":item.active,"items":_vm.users,"editableComponent":'el-autocomplete',"label":"Dispensed By","cell-id":item.medicationDispensedId,"isRequired":true,"displayValue":_vm.getDisplayValue(item.dispensedBy, _vm.users, 'text')},on:{"edit-row":_vm.toggleEdit},model:{value:(item.dispensedBy),callback:function ($$v) {_vm.$set(item, "dispensedBy", $$v)},expression:"item.dispensedBy"}}):_c('div',[_vm._v(" "+_vm._s(_vm.getDisplayValue(item.dispensedBy, _vm.users, 'text'))+" ")])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('DatePickerCell',{ref:("created-" + (item.medicationDispensedId)),attrs:{"edit-active":item.active,"label":"Date","cell-id":item.medicationDispensedId,"disabled":_vm.disabled,"readOnly":true},on:{"edit-row":_vm.toggleEdit},model:{value:(item.created),callback:function ($$v) {_vm.$set(item, "created", $$v)},expression:"item.created"}})]}},(!_vm.disabled)?{key:"item.editRecord",fn:function(ref){
var item = ref.item;
return [(!_vm.isDashboard)?_c('div',{staticClass:"editable_table__edit-record ml-2 absolute text-center",class:{ 'active-tr': item.active}},[(item.active)?_c('a',{staticClass:"mr-1 bg-orange-light",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.cancelHandler.apply(null, arguments)}}},[_c('fa-icon',{staticClass:"text-orange",attrs:{"icon":"times"}})],1):_vm._e(),(item.active)?_c('a',{staticClass:"hover:text-primary bg-primary-lightest",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.saveHandler(item)}}},[_c('fa-icon',{staticClass:"text-primary",attrs:{"icon":"check"}})],1):_vm._e()]):_vm._e(),(_vm.isDashboard)?_c('div',{staticClass:"text-right tablet-hide",class:{ 'active-tr': item.active}},[_c('a',{staticClass:"hover:text-primary mx-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.rowClickHandler(item)}}},[_c('fa-icon',{staticClass:"text-grey-2 ml-3",attrs:{"icon":"chevron-right"}})],1)]):_vm._e()]}}:null,(!_vm.disabled)?{key:"footer",fn:function(){return [(!_vm.isDashboard)?_c('v-btn',{staticClass:"mt-2 v-btn--flat inline",attrs:{"depressed":"","color":"success"},on:{"click":_vm.addMedicationDispensed}},[_c('fa-icon',{staticClass:"mr-1 group-hover:text-primary-light",attrs:{"icon":"plus"}}),_vm._v(" Add another ")],1):_vm._e()]},proxy:true}:null,{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showClientName(item.episode))+" ")]}},{key:"item.episode",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"truncate w-130"},[_vm._v(" "+_vm._s(_vm.getSiteSection(item.episode))+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }