<template>
    <div class="c-phone-log__table editable_table flex flex-col w-full">
        <v-data-table
            ref="filterTable"
            single-select
            item-key="id"
            :headers="headers"
            :items="tableDataArr"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :footer-props="footerProps"
            class="a-table w-full"
            data-cy="phoneLogList"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @click:row="rowClickHandler"
        >
<!--            <template class="" v-slot:item.order="{ item }">-->
<!--                <NumberInputCell-->
<!--                    :ref="`order-${item.phoneLogId}`"-->
<!--                    :edit-active="item.active"-->
<!--                    :cell-id="item.phoneLogId"-->
<!--                    v-model="item.order"-->
<!--                    :disabled="disabled"-->
<!--                    :isRequired="true"-->
<!--                    label="No"-->
<!--                    :rules="[required]"-->
<!--                    @edit-row="toggleEdit"-->
<!--                />-->
<!--            </template>-->
            <template class="" v-slot:item.date="{ item }">
                <DatePickerCell
                    :ref="`date-${item.phoneLogId}`"
                    :edit-active="item.active"
                    label="Date"
                    v-model="item.date"
                    :cell-id="item.phoneLogId"
                    :disabled="disabled"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.time="{ item }">
                <DatetimePickerCell
                    :ref="`time-${item.phoneLogId}`"
                    :edit-active="item.active"
                    :cell-id="item.phoneLogId"
                    v-model="item.time"
                    :disabled="disabled"
                    label="00:00 AM"
                    :isRequired="true"
                    type="time"
                    :format="timeFormat"
                    :rules="[required]"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.type="{ item }">
                <SelectCell
                    :ref="`type-${item.phoneLogId}`"
                    :edit-active="item.active"
                    label="Type"
                    v-model="item.type"
                    :items="types"
                    :isRequired="true"
                    :cell-id="item.phoneLogId"
                    :disabled="disabled"
                    :displayValue="getDisplayValue(item.type, types, 'text')"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.person="{ item }">
               <EditableCell
                   :ref="`person-${item.phoneLogId}`"
                   :edit-active="item.active"
                   :cell-id="item.phoneLogId"
                   v-model="item.person"
                   :disabled="disabled"
                   label="Person"
                   :isRequired="true"
                   :rules="[required]"
                   @edit-row="toggleEdit"
               />
            </template>
            <template class="" v-slot:item.comment="{ item }">
               <EditableCell
                   :ref="`comment-${item.phoneLogId}`"
                   :edit-active="item.active"
                   :cell-id="item.phoneLogId"
                   v-model="item.comment"
                   :disabled="disabled"
                   label="Comments"
                   @edit-row="toggleEdit"
               />
            </template>
            <!-- TODO: when upgrading, need to change below to this: [`item.editRecord`] -->
            <template class="" v-slot:item.editRecord="{ item }" v-if="!disabled">
                <div class="editable_table__edit-record ml-2 absolute text-center" :class="{ 'active-tr': item.active}">
                    <a
                        href="#"
                        v-if="!item.active && isDeletable"
                        @click.prevent="deleteHandler(item)"
                        class="mx-1 bg-red-light"
                    >
                        <fa-icon icon="trash-alt" class="text-red" data-cy="deleteRecord" />
                    </a>
                    <a
                        href="#"
                        v-if="item.active"
                        @click.prevent="cancelHandler"
                        class="mr-1 bg-orange-light"
                    >
                        <fa-icon icon="times" class="text-orange"/>
                    </a>
                    <a
                        href="#"
                        v-if="item.active"
                        @click.prevent="saveHandler(item)"
                        class="hover:text-primary bg-primary-lightest"
                    >
                        <fa-icon icon="check" class="text-primary" />
                    </a>
                </div>
            </template>
            <template v-slot:footer v-if="!disabled">
                <v-btn
                    depressed
                    class="mt-2 v-btn--flat inline"
                    color="success"
                    @click="addPhoneLog"
                >
                    <fa-icon icon="plus" class="mr-1 group-hover:text-primary-light" /> Add another
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { FormEvents, FormRules } from '@/components/shared/mixins/formMixins'
import EditableCell from '@/components/partials/EditableCell'
import DatePickerCell from '@/components/partials/DatePickerCell'
import DatetimePickerCell from '@/components/partials/DatetimePickerCell'
import SelectCell from '@/components/partials/SelectCell'
import INSERT_PHONE_LOG from '@/graphql/mutations/insertPhoneLog.gql'
import DELETE_PHONE_LOG from '@/graphql/mutations/deletePhoneLog.gql'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { format } from 'date-fns'

export default {
    name: 'PhoneLogTable',
    components: { DatePickerCell, DatetimePickerCell, SelectCell, EditableCell },
    mixins: [ FormRules, FormEvents, MessageDialog ],
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        episode: {
            type: Object,
            default: () => {}
        },
        isEditable: {
            type: Boolean,
            default: true
        },
        isDeletable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            emptyText: 'No phone log found',
            duration: 3000,
            type: '',
            message: '',
            itemsPerPage: 10,
            page: 1,
            tableDataArr: [],
            currentNewId: null,
            activeRowId: null,
            currentRow: null,
            footerProps: {
                'items-per-page-options': [10, 20, 30, 40]
            },
            headers: [
                // { text: 'No', align: 'left', value: 'order', class: 'order--header w-50' },
                { text: 'Date', align: 'left', value: 'date', class: 'w-200 date--header' },
                { text: 'Time', align: 'left', value: 'time', class: 'w-130 time--header' },
                { text: 'In/Out', align: 'left', value: 'type', class: 'w-200 type--header' },
                { text: 'Person Called/Calling', align: 'left', value: 'person', class: 'person--header' },
                { text: 'Comments', align: 'left', value: 'comment', class: 'comment--header' },
                { text: '', align: 'left', value: 'editRecord', fixed: true},
            ],
            sortBy: [],
            sortDesc: [true],
            refsToCheck: [
                // 'order',
                'date',
                'time',
                'type',
                'person'
            ],
            defaultPhoneLog: {
                date: format(new Date(), 'yyyy-MM-dd'),
                time: null,
                type: null,
                person: null
            },
            timeFormat: 'h:mm a',
            types: [
                {
                    text: 'In',
                    value: 'IN'
                },
                {
                    text: 'Out',
                    value: 'OUT'
                },
            ]
        }
    },
    mounted() {
        this.tableDataArr = [...this.tableData]
        if (!this.tableDataArr.length) this.addPhoneLog()
    },
    methods: {
        rowClickHandler(obj, e) {
            this.currentRow = obj
            e.select(true)
        },
        addPhoneLog() {
            // if we already have a new behaviour Incident, then we stop the function
            if (this.currentNewId) return
            console.log('adding new phone log row')

            this.currentNewId = Math.floor(Math.random() * 1000000) + 5000000
            this.activeRowId = this.currentNewId
            const tempPhoneLog = {
                phoneLogId: this.currentNewId,
                episodeId: this.episode.episodeId,
                userId: this.loggedInUser.userId,
                ...this.defaultPhoneLog
            }
            this.tableDataArr.push(tempPhoneLog)

            this.page = parseInt((this.tableDataArr.length - 1) / this.itemsPerPage) + 1
        },
        getDisplayValue(val, displayList, prop = 'label') {
            if (!displayList || displayList.length === 0 || !val) return ''

            const displayValue = displayList.find(t => t.value.toLowerCase() === val.toLowerCase())
            return displayValue != null ? displayValue[prop] : ''
        },
        toggleEdit(val) {
            if (!this.isEditable) return false
            if (this.currentNewId) {
                this.showDiscardMessage(false)
                return false
            } else if (this.activeRowId && this.isActiveRowChanged()) {
                this.showDiscardMessage(true)
                return false
            }
            this.activeRowId = val
            this.activeRowObj = this.getActiveRow(this.tableDataArr)
        },
        showDiscardMessage(isEdit) {
            this.type = 'warning'
            this.message = `Please save/discard the ${isEdit ? 'editing' : 'new'} row before moving to other rows.`
            this.showMessage({duration: 1500})
        },
        isActiveRowChanged() {
            // const fields = ['order', 'goal', 'commenced', 'completed']
            const fields = ['goal', 'commenced', 'completed']
            const changedObj = this.getActiveRow(this.tableDataArr)
            if (!changedObj) return false

            return fields.filter(field => this.activeRowObj[field] != changedObj[field]).length > 0
        },
        getActiveRow(data) {
            const activeRow = data.find((item) => {
                return item.phoneLogId === this.activeRowId
            })
            return {...activeRow}
        },
        validateInputs(id) {
            const forms = this.refsToCheck.reduce((arr, curr) => {
                arr.push(this.$refs[`${curr}-${id}`].validate())
                return arr
            }, [])
            return Promise.all(forms)
        },
        saveHandler() {
            this.validateInputs(this.activeRowId).then(values => {
                let isSuccess = true
                values.forEach(value => { if(!value) isSuccess = false })
                if(isSuccess) {
                    // Post back to db
                    // this.mutationCall(this.getActiveRow(this.tableDataArr), false)

                    const data = this.getActiveRow(this.tableDataArr)
                    delete data.updated
                    const idx = this.tableDataArr.findIndex(t => t.phoneLogId === data.phoneLogId)

                    if (data && data.typename) delete data.typename
                    if (this.currentNewId && this.currentNewId === this.activeRowId) delete data.phoneLogId

                    delete data.active
                    const convertedData = snakecaseKeys(data, {deep: true})
                    this.$apollo.mutate({
                        mutation: INSERT_PHONE_LOG,
                        variables: {
                            phoneLog: convertedData
                        }
                    }).then(({ data: { phoneLog } }) => {
                        console.log('phone log returned', phoneLog)
                        if (phoneLog) {
                            this.tableDataArr[idx] = {...camelcaseKeys(phoneLog, {deep: true})}
                        }

                        this.saving = false
                        this.message = 'Saved phone log item'
                        this.type = 'success'

                        this.activeRowId = null
                        this.currentNewId = null
                        this.activeRowObj = null

                        this.$emit('phone-log-mutated')
                    }).catch(error => {
                        this.message = 'Failed saving phone log'
                        this.type = 'error'
                    }).finally(() => {
                        this.saving = false
                        data.active = true
                        this.showMessage({ duration: 5000 })
                    })
                }
            }).catch(err => {
                console.error('validation failed: ', err)
            })
        },
        filterTable(data) {
            return data.map(item => {
                let isActive = item.phoneLogId === this.activeRowId
                return {
                    ...item,
                    active: isActive
                }
            })
        },
        resetTableRow() {
            // if an existing row is being edited
            if(!this.currentNewId) {
                // update the data on the row to the original values stored in activeRowObj
                this.tableDataArr = this.tableDataArr.map(item => {
                    if (item.phoneLogId === this.activeRowId) {
                        return this.activeRowObj
                    }
                    return item
                })
            } else {
                // if its a new staff member, cancel the add by filtering them from the table arr
                this.tableDataArr = this.tableDataArr.filter(item => {
                    if (item.phoneLogId !== this.activeRowId) return item
                })
            }
        },
        deleteHandler(item) {
            this.clearRowSelection()
            this.tableDataArr = this.tableDataArr.filter(td => {
                if (td.phoneLogId !== item.phoneLogId) return td
            })
            this.$apollo.mutate({
                mutation: DELETE_PHONE_LOG,
                variables: {
                    phoneLogId: item.phoneLogId
                }
            }).then(({ data: { phoneLog } }) => {
                console.log('phone log returned', phoneLog)

                this.saving = false
                this.message = 'Deleted phone log'
                this.type = 'success'

                this.activeRowId = null
                this.currentNewId = null
                this.activeRowObj = null

                this.$emit('phone-log-mutated')
            }).catch(error => {
                this.message = 'Failed deleting phone log'
                this.type = 'error'
            }).finally(() => {
                this.saving = false
                this.showMessage({ duration: 5000 })
            })
        },
        cancelHandler() {
            this.resetTableRow()
            this.clearRowSelection()
        },
        clearRowSelection() {
            this.activeRowId = null
            this.currentNewId = null
            this.activeRowObj = null
        }
    },
    computed: {
        ...mapState({
            loggedInUser: state => state.app.loggedInUser,
            staticChoices: state => state.app.staticChoices
        }),
    },
    watch: {
        tableData() {
            this.clearRowSelection()
            this.tableDataArr = [...this.tableData]
            if (!this.tableDataArr.length) this.addPhoneLog()
        },
        activeRowId() {
            this.tableDataArr = this.filterTable(this.tableDataArr)
        }
    }
}
</script>
