<template>
    <div class="bg-white-pure l-box-shadow rounded px-4 py-3 mb-5">
        <h4 class="font-display font-semibold text-left text-lg mb-4">Alerts</h4>
        <AlertTable
            :tableData="alerts"
            :episode="episode"
            :headers="headers"
            :source="source"
            :items-per-page="itemsPerPage"
            :isEditable="$can('edit', 'alerts')"
            :isDeletable="$can('delete', 'alerts')"
            @alert-mutated="refreshAlerts"
        />
    </div>
</template>

<script>
import camelcaseKeys from 'camelcase-keys'
import AlertTable from '@/components/alert/partials/AlertTable'
import GET_ALERTS_FOR_EPISODE from '@/graphql/queries/getAlertsForEpisode.gql'

export default {
    name: 'Alert',
    components: { AlertTable },
    props: {
        episode: {
            type: Object,
            default: () => {}
        }
    },
    apollo: {
        alerts: {
            query: GET_ALERTS_FOR_EPISODE,
            variables() {
                const episodeIdCompare = this.episode?.episodeId ? {_eq: Number(this.episode.episodeId)} : {}
                return {
                    episodeIdCompare,
                }
            },
            skip() {
                if (!this.episode || !this.episode.episodeId) {
                    this.alerts = []
                    return true
                }
                return false
            },
            result({ data: { alerts } }) {
                let convertedAlerts = camelcaseKeys(alerts, {deep: true})
                this.alerts = [...convertedAlerts]
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            }
        }
    },
    data() {
        return {
            alerts: [],
            source: this.$route.name,
            headers: [
                { text: 'Active', align: 'left', value: 'relevant', class: 'relevant--header w-50' },
                // { text: 'No', align: 'left', value: 'order', class: 'order--header w-50' },
                { text: 'Date', align: 'left', value: 'date', class: 'w-200 date--header' },
                { text: 'Time', align: 'left', value: 'time', class: 'w-130 time--header' },
                { text: 'Type', align: 'left', value: 'type', class: 'w-200 type--header' },
                { text: 'Level', align: 'left', value: 'level', class: 'w-200 level--header' },
                { text: 'Description', align: 'left', value: 'desc', class: 'desc--header' },
                { text: '', align: 'left', value: 'editRecord', fixed: true},
            ],
            itemsPerPage: 10,
        }
    },
    methods: {
        refreshAlerts() {
            this.$apollo.queries.alerts.refetch()
        }
    }
}
</script>
