<template>
    <div class="c-alert__table editable_table flex flex-col w-full">
        <v-data-table
            ref="filterTable"
            single-select
            item-key="id"
            :headers="headers"
            :items="tableDataArr"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :footer-props="footerProps"
            class="a-table w-full attach-table relative"
            data-cy="alertList"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :no-data-text="emptyText"
            :no-results-text="emptyText"
            @click:row="rowClickHandler"
        >
            <template class="" v-slot:item.dispensedDate="{ item }">
                <DatePickerCell
                    :ref="`dispensedDate-${item.medicationDispensedId}`"
                    :edit-active="item.active"
                    label="Date"
                    v-model="item.dispensedDate"
                    :cell-id="item.medicationDispensedId"
                    :attachClass="'.attach-table'"
                    :disabled="disabled"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.time="{ item }">
                <DatetimePickerCell
                    :ref="`time-${item.medicationDispensedId}`"
                    :edit-active="item.active"
                    :cell-id="item.medicationDispensedId"
                    v-model="item.time"
                    :disabled="disabled"
                    label="00:00 AM"
                    :isRequired="true"
                    type="time"
                    :format="timeFormat"
                    :rules="[required]"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.createdBy="{ item }">
                <div v-if="item.createdByUser">
                    {{item.createdByUser.name}}
                </div>
                <div v-else>
                    {{item.createdName}}
                </div>
            </template>
            <template class="" v-slot:item.medication="{ item }">
               <EditableCell
                   :ref="`medication-${item.medicationDispensedId}`"
                   :edit-active="item.active"
                   :cell-id="item.medicationDispensedId"
                   v-model="item.medication"
                   :disabled="disabled"
                   label="Medication"
                   @edit-row="toggleEdit"
               />
            </template>
            <template class="" v-slot:item.number="{ item }">
                <NumberInputCell
                    :ref="`number-${item.medicationDispensedId}`"
                    :edit-active="item.active"
                    :cell-id="item.medicationDispensedId"
                    v-model="item.number"
                    :disabled="disabled"
                    :isRequired="true"
                    label="No."
                    :rules="[required]"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.strength="{ item }">
                <NumberInputCell
                    :ref="`strength-${item.medicationDispensedId}`"
                    :edit-active="item.active"
                    :cell-id="item.medicationDispensedId"
                    v-model="item.strength"
                    :disabled="disabled"
                    :isRequired="true"
                    label="Dose."
                    :rules="[required]"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.dispensedBy="{ item }">
                <SelectCell
                    v-if="!isDashboard"
                    :ref="`dispensedBy-${item.medicationDispensedId}`"
                    :edit-active="item.active"
                    :items="users"
                    :editableComponent="'el-autocomplete'"
                    label="Dispensed By"
                    v-model="item.dispensedBy"
                    :cell-id="item.medicationDispensedId"
                    :isRequired="true"
                    :displayValue="getDisplayValue(item.dispensedBy, users, 'text')"
                    @edit-row="toggleEdit"
                />
                <div v-else>
                    {{ getDisplayValue(item.dispensedBy, users, 'text') }}
                </div> 
            </template>
            <template class="" v-slot:item.created="{ item }">
                <DatePickerCell
                    :ref="`created-${item.medicationDispensedId}`"
                    :edit-active="item.active"
                    label="Date"
                    v-model="item.created"
                    :cell-id="item.medicationDispensedId"
                    :disabled="disabled"
                    :readOnly="true"
                    @edit-row="toggleEdit"
                />
            </template>
            
            <!-- TODO: when upgrading, need to change below to this: [`item.editRecord`] -->
            <template class="" v-slot:item.editRecord="{ item }" v-if="!disabled">
                <div class="editable_table__edit-record ml-2 absolute text-center" :class="{ 'active-tr': item.active}" v-if="!isDashboard">
                    <a
                        href="#"
                        v-if="item.active"
                        @click.prevent="cancelHandler"
                        class="mr-1 bg-orange-light"
                    >
                        <fa-icon icon="times" class="text-orange" />
                    </a>
                    <a
                        href="#"
                        v-if="item.active"
                        @click.prevent="saveHandler(item)"
                        class="hover:text-primary bg-primary-lightest"
                    >
                        <fa-icon icon="check" class="text-primary" />
                    </a>
                </div>
                <div class="text-right tablet-hide" :class="{ 'active-tr': item.active}" v-if="isDashboard">
                    <a
                        href="#"
                        class="hover:text-primary mx-2"
                        @click.prevent="rowClickHandler(item)"
                    >
                        <fa-icon icon="chevron-right" class="text-grey-2 ml-3" />
                    </a>
                </div>
            </template>
            <template v-slot:footer v-if="!disabled">
                <v-btn
                    v-if="!isDashboard"
                    depressed
                    class="mt-2 v-btn--flat inline"
                    color="success"
                    @click="addMedicationDispensed"
                >
                    <fa-icon icon="plus" class="mr-1 group-hover:text-primary-light" /> Add another
                </v-btn>
            </template>
            <template class="" v-slot:item.name="{ item }">
                {{ showClientName(item.episode) }}
            </template>
            <template class="" v-slot:item.episode="{ item }">
                <div class="truncate w-130">
                    {{ getSiteSection(item.episode) }}
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { FormEvents, FormRules } from '@/components/shared/mixins/formMixins'
import { EpisodeHelpers } from '@/components/shared/mixins/episodeMixins'
import { ClientHelpers } from '@/components/shared/mixins/clientMixins'
import CheckboxCell from '@/components/partials/CheckboxCell'
import EditableCell from '@/components/partials/EditableCell'
import DatePickerCell from '@/components/partials/DatePickerCell'
import DatetimePickerCell from '@/components/partials/DatetimePickerCell'
// import SelectCell from '@/components/partials/SelectCell'
import NumberInputCell from '@/components/partials/NumberInputCell'
import INSERT_MEDICATION_DISPENSED from '@/graphql/mutations/insertMedicationDispensed.gql'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { format } from 'date-fns'
import { SharedMixin } from '@/helpers/displayValue'
import SelectCell from '@/components/partials/ElSelectCell'

export default {
    name: 'MedicationDispensedTable',
    components: { CheckboxCell, DatePickerCell, DatetimePickerCell, SelectCell, EditableCell, NumberInputCell},
    mixins: [ FormRules, FormEvents, MessageDialog, EpisodeHelpers, ClientHelpers, SharedMixin ],
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        episode: {
            type: Object,
            default: () => {}
        },
        users: {
            type: Array,
            default: () => []
        },
        headers: {
            type: Array,
            default: () => []
        },
        source: {
            type: String,
            default: ''
        },
        itemsPerPage: {
            type: Number,
            default: 10
        },
        isEditable: {
            type: Boolean,
            default: true
        },
        isDeletable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            emptyText: 'No active medication dispended found',
            duration: 3000,
            type: '',
            message: '',
            page: 1,
            tableDataArr: [],
            currentNewId: null,
            activeRowId: null,
            currentRow: null,
            clientId:'',
            footerProps: {
                'items-per-page-options': [5, 10, 20, 30, 40]
            },
            sortBy: [],
            sortDesc: [true],
            refsToCheck: [
                'dispensedDate',
                'medication',
                'number',
                'strength',
                'dispensedBy',
                'time'
            ],
            // requiredFields: [
            //     'dispensedDate',
            //     'number',
            //     'dispensedBy',
            // ],
            defaultMedicationDispensed: {
                dispensedDate: format(new Date(), 'yyyy-MM-dd'),
                medication: null,
                number: null,
                strength: null,
                created: format(new Date(), 'yyyy-MM-dd'),
                dispensedBy: null,
                time: null
            },
            timeFormat: 'h:mm a'
        }
    },
    mounted() {
        this.tableDataArr = [...this.tableData]
    },
    methods: {
        rowClickHandler(obj, e) {
            this.currentRow = obj
            if(this.isDashboard) {
                this.clientId = obj.episode.client.clientId
                this.$router.push({
                    name: 'episodes',
                    params: {clientId: this.clientId},
                }).catch(err => err)
            }
            e.select(true)
        },
        levelClass(level) {
            switch(level) {
                case 'HIGH_ALERT':
                    return 'text-red'
                case 'MONITORING':
                    return 'text-orange'
                case 'LOW':
                    return 'text-yellow'
            }
        },
        addMedicationDispensed() {
            // if we already have a new behaviour Incident, then we stop the function
            if (this.currentNewId) return
            console.log('adding new medication dispensed row')

            this.currentNewId = Math.floor(Math.random() * 1000000) + 5000000
            this.activeRowId = this.currentNewId
            const tempAlert = {
                medicationDispensedId: this.currentNewId,
                episodeId: this.episode?.episodeId,
                userId: this.loggedInUser?.userId,
                createdName: this.loggedInUser?.name,
                ...this.defaultMedicationDispensed,
            }
            this.tableDataArr.push(tempAlert)

            this.page = parseInt((this.tableDataArr.length - 1) / this.itemsPerPage) + 1
        },
        toggleEdit(val) {
            if (
                this.activeRowId && Number(this.activeRowId) === Number(val) ||
                this.currentNewId && Number(this.currentNewId) === Number(val) ||
                !this.isEditable
            ) return
            if (this.currentNewId) {
                this.showDiscardMessage(false)
                return false
            } else if (this.activeRowId && this.isActiveRowChanged()) {
                this.showDiscardMessage(true)
                return false
            }
            this.activeRowId = val
            this.activeRowObj = this.getActiveRow(this.tableDataArr)
        },
        showDiscardMessage(isEdit) {
            this.type = 'warning'
            this.message = `Please save/discard the ${isEdit ? 'editing' : 'new'} row before moving to other rows.`
            this.showMessage({duration: 1500})
        },
        isActiveRowChanged() {
            // const fields = ['order', 'goal', 'commenced', 'completed']
            const fields = ['goal', 'commenced', 'completed']
            const changedObj = this.getActiveRow(this.tableDataArr)
            if (!changedObj) return false

            return fields.filter(field => this.activeRowObj[field] != changedObj[field]).length > 0
        },
        getActiveRow(data) {
            const activeRow = data.find((item) => {
                return item.medicationDispensedId === this.activeRowId
            })
            return {...activeRow}
        },
        validateInputs(id) {
            const forms = this.refsToCheck?.reduce((arr, curr) => {
                arr.push(this.$refs[`${curr}-${id}`].validate())
                return arr
            }, [])
            return Promise.all(forms)
        },
        saveHandler() {
            this.validateInputs(this.activeRowId).then(values => {
                let isSuccess = true
                values.forEach(value => { if(!value) isSuccess = false })
                if(isSuccess) {
                    // Post back to db
                    // this.mutationCall(this.getActiveRow(this.tableDataArr), false)

                    const data = this.getActiveRow(this.tableDataArr)
                    delete data.created
                    
                    const idx = this.tableDataArr.findIndex(t => t.medicationDispensedId === data.medicationDispensedId)

                    if (data && data.typename) delete data.typename
                    if (this.currentNewId && this.currentNewId === this.activeRowId) delete data.medicationDispensedId
                    delete data.episode
                    delete data.active
                    delete data.createdName
                    delete data.userId
                    delete data.createdByUser
                    delete data.dispensedByUser
                    delete data.createdBy
                    data.number = Number(data.number)
                    const convertedData = snakecaseKeys(data, {deep: true})
                    this.$apollo.mutate({
                        mutation: INSERT_MEDICATION_DISPENSED,
                        variables: {
                            medicationDispensed: convertedData
                        }
                    }).then(({ data: { medicationDispensed } }) => {
                        console.log('medication dispensed returned', medicationDispensed)
                        if (medicationDispensed) {
                            let newMedicationDispensed = {...camelcaseKeys(medicationDispensed, {deep: true})}
                            newMedicationDispensed.number = newMedicationDispensed.number.toString();
                            this.tableDataArr[idx] = {...camelcaseKeys(newMedicationDispensed, {deep: true})}
                        }

                        this.saving = false
                        this.message = 'Saved medication dispensed item'
                        this.type = 'success'

                        this.activeRowId = null
                        this.currentNewId = null
                        this.activeRowObj = null

                        this.$emit('medication-dispensed-mutated')
                    }).catch(error => {
                        console.log('error 44444444444: ', error)
                        this.message = 'Failed saving medication dispensed'
                        this.type = 'error'
                    }).finally(() => {
                        this.saving = false
                        data.active = true
                        this.showMessage({ duration: 5000 })
                    })
                }
            }).catch(err => {
                console.error('validation failed: ', err)
            })
        },
        filterTable(data) {
            return data.map(item => {
                let isActive = item.medicationDispensedId === this.activeRowId
                return {
                    ...item,
                    active: isActive
                }
            })
        },
        resetTableRow() {
            // if an existing row is being edited
            if(!this.currentNewId) {
                // update the data on the row to the original values stored in activeRowObj
                this.tableDataArr = this.tableDataArr.map(item => {
                    if (item.medicationDispensedId === this.activeRowId) {
                        return this.activeRowObj
                    }
                    return item
                })
            } else {
                // if its a new staff member, cancel the add by filtering them from the table arr
                this.tableDataArr = this.tableDataArr.filter(item => {
                    if (item.medicationDispensedId !== this.activeRowId) return item
                })
            }
        },

        cancelHandler() {
            this.resetTableRow()
            this.clearRowSelection()
        },
        clearRowSelection() {
            this.activeRowId = null
            this.currentNewId = null
            this.activeRowObj = null
        },
        showClientName(episode) {
            return this.getFullName(episode?.client)
        }
    },
    computed: {
        ...mapState({
            loggedInUser: state => state.app.loggedInUser,
            staticChoices: state => state.app.staticChoices,
            siteSections: state => state.app.siteSections
        }),
        isDashboard() {
            return this.source === 'dashboard'
        }
    },
    watch: {
        tableData() {
            this.clearRowSelection()
            this.tableDataArr = [...this.tableData]
        },
        activeRowId() {
            this.tableDataArr = this.filterTable(this.tableDataArr)
        }
    }
}
</script>
