<template>
    <div>
        <component
            :is="renderForm"
            :model="isValid"
            ref="formInput"
            class="c-editable-cell"
            @submit.prevent=""
        >
            <component
                dense single-line hide-details
                :is="renderEditableComponent"
                v-show="editActive"
                @focus="onCellClick"
                v-model="formInput.inputValue"
                class="p-0 m-0"
                :type="inputType"
                :rules="isRequired ? rules : []"
                :label="label"
                @keypress="isNumber($event)"
            >
                <slot></slot>
            </component>
        </component>
        <el-link
            @click="cellClickHandler"
            v-show="!editActive"
            class="justify-start py-1 m-0 hover:text-grey-1 text-grey-2 leading-normal border border-transparent border-l-0 border-r-0 border-t-0"
            :v-html="!linkText ? '&nbsp;': ''"
            :class="{'w-4/5' : !linkText, 'fieldEmpty': !formInput.inputValue, 'requiredField': this.isRequired}"
        >
            {{ linkText }}
        </el-link>
    </div>
</template>

<script>
import { FormRules } from '@/components/shared/mixins/formMixins'

export default {
    name: 'NumberInputCell',
    mixins: [ FormRules ],
    props: {
        value: {
            type: String,
            default: ''
        },
        displayValue: {
            type: String,
            default: ''
        },
        cellId: {
            type: [Number, String],
            default: null
        },
        editableComponent: {
            type: String,
            default: 'v-text-field'
        },
        editActive: {
            type: Boolean,
            default: null
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        defaultValue: {
            type: String,
            default: ''
        },
        inputType: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: 'Enter value'
        },
        rules: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            isValid: true,
            decimalPointKeyCode: 46,
            focused: false,
            formInput: {inputValue: ''},
            defaultRules: [
                this.required
            ],
        }
    },
    mounted() {
        this.formInput.inputValue = this.value || this.defaultValue
        if (!this.rules) this.rules = this.defaultRules
    },
    methods: {
        cellClickHandler() {
            if (this.disabled) return false

            this.focused = true
            this.$emit('edit-row', this.cellId)
        },
        onCellClick(e) {
            console.log('onCellClick', e)
        },
        validate(){
            this.isValid = this.$refs.formInput.validate()
            return this.isValid
        },
        clearValidate(){
            this.isValid = true
            return this.isValid
        },
        isNumber(event) {
            event = event || window.event
            let charCode = event.which || event.keyCode
            console.log('event', this.event)
            if ((charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) || (charCode === this.decimalPointKeyCode && this.value.indexOf('.') !== -1)) {
                event.preventDefault()
            } else {
                return true
            }
            return false
        }
    },
    computed: {
        linkText(){
            let text =  this.displayValue || this.value
            return text || ''
        },
        renderEditableComponent() {
            if (this.editActive) return this.editableComponent
            return ''
        },
        renderForm() {
            if (this.editActive) return 'v-form'
            return ''
        },
    },
    watch: {
        value() {
            this.formInput.inputValue = this.value
        },
        'formInput.inputValue': function() {
            if (this.editActive) this.$emit('input', this.formInput.inputValue)
        },
    },
}
</script>
