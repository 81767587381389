<template>
    <div class="bg-white-pure l-box-shadow rounded px-4 py-3 mb-5 w-full">
        <h4 class="font-display font-semibold text-left text-lg mb-4">Documents</h4>
        <div class="grid grid-cols-3 gap-8 mt-4 w-full">
            <UploadInterface
                ref="uploadInterface"
                class="col-span-1"
                :accept="allowedFileTypes"
                @fileAdded="onFileAdded"
                @uploadSuccess="onUploadSuccess"
                @uploadError="onUploadError"
            />
            <DocumentTable
                class="col-span-2"
                :tableData="documents"
                :isDeletable="$can('delete', 'documents')"
                @downloadDocument="downloadDocument"
                @deleteDocument="deleteDocument"
            />
        </div>
    </div>
</template>

<script>
import camelcaseKeys from 'camelcase-keys'
import DocumentTable from './partials/DocumentTable'
import GET_DOCUMENTS_FOR_EPISODE from '@/graphql/queries/getDocumentsForEpisode.gql'
import DELETE_DOCUMENT from '@/graphql/mutations/deleteDocument.gql'
import axiosInstance from '@/utils/axios'
import UploadInterface from '@/components/partials/UploadInterface'
import FileMethods from '@/components/shared/mixins/fileMethods'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import snakecaseKeys from 'snakecase-keys'
import { mapState } from 'vuex'
import { format } from 'date-fns'

export default {
    name: 'Documents',
    components: { DocumentTable, UploadInterface },
    mixins: [ FileMethods, MessageDialog ],
    props: {
        episode: {
            type: Object,
            default: () => {}
        }
    },
    apollo: {
        documents: {
            query: GET_DOCUMENTS_FOR_EPISODE,
            variables() {
                return {
                    episodeId: Number(this.episode.episodeId),
                }
            },
            skip() {
                if (!this.episode || !this.episode.episodeId) {
                    this.documents = []
                    return true
                }
                return false
            },
            result({ data: { documents } }) {
                let convertedDocuments = camelcaseKeys(documents, {deep: true})
                this.documents = [...convertedDocuments]
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            }
        }
    },
    data() {
        return {
            documents: [],
            currentDocumentPk: null,
            fileInfoForUpload: {},
            message: '',
            type: '',
            allowedFileTypes: [
                '.jpg',
                'image/png',
                'application/pdf',
                'application/msword',
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ].join(','),
        }
    },
    computed: {
        ...mapState({
            loggedInUser: state => state.app.loggedInUser
        })
    },
    methods: {
        refreshDocuments() {
            this.$apollo.queries.documents.refetch()
        },
        downloadDocument(item) {
            axiosInstance.get(`/document/${ item.documentId }/`).then(({ data : { urls }}) => {
                this.downloadRemoteFileHandler(item.file.split('/')[item.file.split('/').length - 1], urls, 'GET', null)
            }).catch((error) => {
                this.type = 'error'
                this.message = 'Failed to download document'
                this.showMessage({duration: 5000})
            })
        },
        deleteDocument(item) {
            axiosInstance.put(`/document/${ item.documentId }/`, { achived: true }).then((response) => {
                return this.$apollo.mutate({
                    mutation: DELETE_DOCUMENT,
                    variables: {
                        documentId: item.documentId
                    }
                })
            }).then((res) => {
                this.type = 'success'
                this.message = 'File delete was successful!'
                this.refreshDocuments()
            }).catch((error) => {
                this.type = 'error'
                this.message = 'Failed to delete document'
            }).finally(() => {
                this.showMessage({duration: 5000})
            })
        },
        onFileAdded(file) {
            const { name, size, type } = file
            this.fileInfoForUpload.name = name;
            this.fileInfoForUpload.size = size;
            this.fileInfoForUpload.type = type;

            const payload = {
                file: this.fileInfoForUpload.name,
                desc: this.fileInfoForUpload.name,
                episode: this.episode.episodeId,
                user: this.loggedInUser.userId,
                date: format(new Date(), 'yyyy-MM-dd'),
            }

            axiosInstance.post('/document/', payload).then(({ data: { pk, urls, timeout } }) => {
                this.currentDocumentPk = pk
                
                this.$refs.uploadInterface.setUploadUrl(urls.file)
                this.$refs.uploadInterface.startUpload()
            }).catch((error) => {
                this.type = 'error'
                this.message = (error.response.data && typeof error.response.data === 'string' ? error.response.data : 'Failed to get presigned url, please try again')
                this.showMessage({duration: 5000})
            })
        },
        onUploadSuccess() {
            let payload = {
                id: this.currentDocumentPk,
                fileSize: this.fileInfoForUpload.size,
                fileType: this.fileInfoForUpload.type
            }

            payload = snakecaseKeys(payload, { deep: true })
            axiosInstance.put(`/document/${this.currentDocumentPk}/`, payload).then((response) => {
                this.type = 'success'
                this.message = 'File upload was successful!'
                this.refreshDocuments()
            }).catch((error) => {
                this.type = 'error'
                this.message = (error.response.data && typeof error.response.data === 'string' ? error.response.data : 'Failed to upload file(s), please try again')
            }).finally(() => {
                this.showMessage({duration: 5000})
            })
        },
        onUploadError(err) {
            let message = err?.message
            if (!message) message = 'Error uploading file'

            this.message = message
            this.type = 'error'
            this.showMessage({duration: 5000})
        },
    }
}
</script>
