<template>
    <div>
        <div class="bg-white-pure l-box-shadow rounded px-4 py-3 mb-5">
            <h4 class="font-display font-semibold text-left text-lg mb-4">Telephone Contacts</h4>
            <TelephoneContact :episode="episode" />
        </div>
        <div class="bg-white-pure l-box-shadow rounded px-4 py-3 mb-5">
            <h4 class="font-display font-semibold text-left text-lg mb-4">Phone Call Log</h4>
            <PhoneLog :episode="episode" />
        </div>
    </div>
</template>

<script>

import TelephoneContact from '@/components/telephone-contact/TelephoneContact'
import PhoneLog from '@/components/phone-log/PhoneLog'

export default {
    name: 'ContactLog',
    components: {
        TelephoneContact,
        PhoneLog
    },
    props: {
        episode: {
            type: Object,
            default: () => {}
        }
    }
}
</script>
