<template>
    <div @click="containerClick" class="upload-file-container cursor-pointer">
        <DocumentSvg class="m-4" :style="svgStyle" />
        <v-btn
            color="primary"
            rounded
            depressed
            :loading="uploading"
        >
            Click To Upload
        </v-btn>
        <input
            ref="uploader"
            class="hidden"
            type="file"
            :accept="accept"
            @change="onFileChanged"
        >
    </div>
</template>

<script>
import DocumentSvg from '@/assets/documents.svg'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import { MAX_FILE_SIZE } from '@/utils/constants'

export default {
    name: 'UploadInterface',
    mixins: [ HttpMixin ],
    components: { DocumentSvg },
    props: {
        accept: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            svgStyle: {
                width: '120px',
                height: '131px',
                marginRight: 'auto',
                marginLeft: 'auto',
            },
            uploading: false,
            url: ''
        }
    },
    methods: {
		containerClick() {
            if (!this.uploading) this.$refs.uploader.click()
		},
        onFileChanged(eve) {
            if (!eve.target.files?.length) return

            this.selectedFile = eve.target.files[0]
            if (!this.selectedFile?.name) return

            if(this.isFileToBig()) return

            const { name, size, type } = this.selectedFile
            this.$emit('fileAdded', { name, size, type })
        },
        isFileToBig() {
            const fileSize = this.selectedFile.size / Math.pow(1024, 2)

            if (MAX_FILE_SIZE === undefined) {
                this.$emit('uploadError', {message: 'Maximum upload size is not defined'})
                return true
            }

            if (fileSize > MAX_FILE_SIZE) {
                console.error(`Maximum upload size is ${MAX_FILE_SIZE} MB but the current size is ${Math.round(fileSize)} MB`)
                this.$emit('uploadError', {message: `Maximum upload size is ${MAX_FILE_SIZE} MB`})
                return true
            }

            return false
        },
        startUpload() {
            const options = {
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            };

            this.uploading = true

            this.$http.put(this.url, this.selectedFile, options).then((response) => {
                this.$emit('uploadSuccess', response)
            }).catch((error) => {
                this.$emit('uploadError', error)
            }).finally(() => {
                this.uploading = false
                this.selectedFile = null;
                this.$refs.uploader.value = null
            })
        },
        setUploadUrl(url) {
            this.url = url
        }
    },
}
</script>
