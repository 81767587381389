<template>
    <div>
        <component
            :is="renderForm"
            :model="isValid"
            ref="formInput"
            class="c-editable-cell"
            @submit.prevent=""
        >
            <div prop="phone">
                <component
                    dense single-line hide-details
                    :is="renderEditableComponent"
                    v-show="editActive"
                    @focus="onCellClick"
                    v-model="formInput.inputValue.phone"
                    class="p-0 m-0"
                    :rules="isRequired ? rules : []"
                    :label="label"
                    default-country-code="AU"
                    :translations="translations"
                    @phone-number-blur="onPhoneBlur"
                    @update="updatePhone"
                >
                    <slot></slot>
                </component>
            </div>
        </component>
        <a
            @click="cellClickHandler"
            v-show="!editActive"
            class="justify-start py-1 m-0 hover:text-grey-1 text-grey-2 leading-normal border border-transparent border-l-0 border-r-0 border-t-0"
            :v-html="!linkText ? '&nbsp;': ''"
            :class="{'w-4/5' : !linkText, 'fieldEmpty': !formInput.inputValue.phone, 'requiredField': this.isRequired}"
        >
            {{ linkText }}
        </a>
    </div>
</template>

<script>
import { FormRules } from '@/components/shared/mixins/formMixins'

export default {
    name: 'PhoneNumberCell',
    mixins: [ FormRules ],
    props: {
        value: {
            type: Object,
            default: () =>  {
                return {
                    countryCode: 'AU',
                    phone: '',
                    archived: false
                }
            }
        },
        displayValue: {
            type: String,
            default: ''
        },
        cellId: {
            type: Number,
            default: null
        },
        editableComponent: {
            type: String,
            default: 'v-phone-input'
        },
        editActive: {
            type: Boolean,
            default: null
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        defaultValue: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'Enter value'
        },
        rules: {
            type: Array,
            default: () => []
        },
        validPhone: {
            type: Boolean,
            default: false
        },
        translations: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            firstLoad: true,
            isValid: true,
            phoneRegex: /^\(?(?:\+?61|0)(?:(?:2\)?[ -]?(?:3[ -]?[38]|[46-9][ -]?[0-9]|5[ -]?[0-35-9])|3\)?(?:4[ -]?[0-57-9]|[57-9][ -]?[0-9]|6[ -]?[1-67])|7\)?[ -]?(?:[2-4][ -]?[0-9]|5[ -]?[2-7]|7[ -]?6)|8\)?[ -]?(?:5[ -]?[1-4]|6[ -]?[0-8]|[7-9][ -]?[0-9]))(?:[ -]?[0-9]){6}|4\)?[ -]?(?:(?:[01][ -]?[0-9]|2[ -]?[0-57-9]|3[ -]?[1-9]|4[ -]?[7-9]|5[ -]?[018])[ -]?[0-9]|3[ -]?0[ -]?[0-5])(?:[ -]?[0-9]){5})$/,
            focused: false,
            formInput: {
                inputValue: {
                    countryCode: 'AU',
                    phone: '',
                    archived: false
                }
            },
            defaultRules: [
                this.required,
                this.validateInput
            ],
        }
    },
    mounted() {
        this.formInput.inputValue = this.value || this.defaultValue
        if (!this.rules) this.rules = this.defaultRules
    },
    methods: {
        cellClickHandler() {
            if (this.disabled) return false

            this.focused = true
            this.$emit('edit-row', this.cellId)
        },
        onCellClick(e) {
            console.log('onCellClick', e)
        },
        validate(){
            this.isValid = this.$refs.formInput.validate()
            return this.isValid
        },
        validateInput() {
            // first we check to see if we just loaded and there's no validation on the phone number
            if (!this.isValid) {
                this.isValid = this.phoneRegex.test(this.formInput.inputValue.phone)
            }

            // first we check if we are adding or editing
            if (this.required) {
                if (!this.formInput.inputValue.phone) {
                    this.validMessage = 'This field is required'
                    this.isValid = false
                } else if (this.isValid !== undefined && this.isValid !== true) {
                    // if isValid is undefined, then the value would be from the backend and we don't need to do anything
                    this.validMessage = 'Invalid phone number format'
                    this.isValid = false
                } else {
                    this.validMessage = ''
                    this.isValid = true
                }
            } else if(this.validPhone) {
                if (this.isValid !== true && this.formInput.inputValue.phone !==  '') {
                    // if isValid is undefined, then the value would be from the backend and we don't need to do anything
                    this.validMessage = 'Invalid phone number format'
                    this.isValid = false
                } else {
                    this.validMessage = ''
                    this.isValid = true
                }
            } else {
                this.validMessage = ''
                this.isValid = true
            }
        },
        clearValidate(){
            this.isValid = true
            return this.isValid
        },
        onPhoneBlur() {
            //TODO: add in the Vuetify way of validating
            // if (this.isValid) {
            //     this.$refs.phoneNumberForm.resetFields()
            // } else {
            //     this.$refs.phoneNumberForm.validate()
            // }
            this.validateInput()
            this.$emit('blur', this.formInput.inputValue)
        },
        updatePhone(e) {
            this.isValid = e.isValid
        }
    },
    computed: {
        linkText(){
            let text =  this.displayValue || this.value.phone
            return text || ''
        },
        renderEditableComponent() {
            if (this.editActive) return this.editableComponent
            return ''
        },
        renderForm() {
            if (this.editActive) return 'v-form'
            return ''
        },
    },
    watch: {
        value() {
            this.formInput.inputValue = this.value
        },
        'formInput.inputValue': function() {
            console.log('inputvalue: ', this.formInput.inputValue)
            if (this.editActive) this.$emit('input', this.formInput.inputValue)
        }
    }
}
</script>
