<template>
    <div>
        <div class="bg-white-pure l-box-shadow rounded px-4 py-3 mb-5">
            <h4 class="font-display font-semibold text-left text-lg mb-4">Medications</h4>
            <MedicationsView :episode="episode" />
        </div>
        <div class="bg-white-pure l-box-shadow rounded px-4 py-3 mb-5">
            <h4 class="font-display font-semibold text-left text-lg mb-4">Medications Dispensed</h4>
            <MedicationDispensed :episode="episode" />
        </div>
    </div>
</template>

<script>
import MedicationsView from '@/components/medications/MedicationsView'
import MedicationDispensed from '@/components/medication/MedicationDispensed.vue';
export default {
    name: 'Medications',
    components: {
        MedicationsView,
        MedicationDispensed
    },
    props: {
        episode: {
            type: Object,
            default: () => { }
        }
    }
}
</script>