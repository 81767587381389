<template>
    <div class="c-analysis-result__table editable_table flex flex-col w-full">
        <v-data-table
            ref="filterTable"
            single-select
            item-key="id"
            :headers="headers"
            :items="tableDataArr"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :footer-props="footerProps"
            class="a-table w-full"
            data-cy="analysisResultList"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @click:row="rowClickHandler"
        >
<!--            <template class="" v-slot:item.order="{ item }">-->
<!--                <NumberInputCell-->
<!--                    :ref="`order-${item.analysisResultId}`"-->
<!--                    :edit-active="item.active"-->
<!--                    :cell-id="item.analysisResultId"-->
<!--                    v-model="item.order"-->
<!--                    :disabled="disabled"-->
<!--                    :isRequired="true"-->
<!--                    label="No"-->
<!--                    :rules="[required]"-->
<!--                    @edit-row="toggleEdit"-->
<!--                />-->
<!--            </template>-->
            <template class="" v-slot:item.dateTaken="{ item }">
                <DatePickerCell
                    :ref="`dateTaken-${item.analysisResultId}`"
                    :edit-active="item.active"
                    label="Date Taken"
                    v-model="item.dateTaken"
                    :cell-id="item.analysisResultId"
                    :disabled="disabled"
                    :isRequired="true"
                    :rules="[required]"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.reason="{ item }">
               <EditableCell
                    :ref="`reason-${item.analysisResultId}`"
                    :edit-active="item.active"
                    :cell-id="item.analysisResultId"
                    v-model="item.reason"
                    :disabled="disabled"
                    label="Reason"
                    :isRequired="true"
                    :rules="[required]"
                    @edit-row="toggleEdit"
               />
            </template>
            <template class="" v-slot:item.resultDate="{ item }">
                <DatePickerCell
                    :ref="`resultDate-${item.analysisResultId}`"
                    :edit-active="item.active"
                    label="Result Date"
                    v-model="item.resultDate"
                    :cell-id="item.analysisResultId"
                    :isRequired="true"
                    :rules="[]"
                    :disabled="disabled"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.result="{ item }">
               <EditableCell
                    :ref="`result-${item.analysisResultId}`"
                    :edit-active="item.active"
                    :cell-id="item.analysisResultId"
                    v-model="item.result"
                    :disabled="disabled"
                    label="Result"
                    :isRequired="true"
                    :rules="[]"
                    @edit-row="toggleEdit"
               />
            </template>
            <!-- TODO: when upgrading, need to change below to this: [`item.editRecord`] -->
            <template class="" v-slot:item.editRecord="{ item }" v-if="!disabled">
                <div class="editable_table__edit-record ml-2 absolute text-center" :class="{ 'active-tr': item.active}">
                    <a
                        href="#"
                        v-if="!item.active && isDeletable"
                        @click.prevent="deleteHandler(item)"
                        class="mx-1 bg-red-light"
                    >
                        <fa-icon icon="trash-alt" class="text-red" data-cy="deleteRecord" />
                    </a>
                    <a
                        href="#"
                        v-if="item.active"
                        @click.prevent="cancelHandler"
                        class="mr-1 bg-orange-light"
                    >
                        <fa-icon icon="times" class="text-orange"/>
                    </a>
                    <a
                        href="#"
                        v-if="item.active"
                        @click.prevent="saveHandler(item)"
                        class="hover:text-primary bg-primary-lightest"
                    >
                        <fa-icon icon="check" class="text-primary" />
                    </a>
                </div>
            </template>
            <template v-slot:footer v-if="!disabled">
                <v-btn
                    depressed
                    class="mt-2 v-btn--flat inline"
                    color="success"
                    @click="addAnalysisResult"
                >
                    <fa-icon icon="plus" class="mr-1 group-hover:text-primary-light" /> Add another
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { FormRules } from '@/components/shared/mixins/formMixins'
import EditableCell from '@/components/partials/EditableCell'
import DatePickerCell from '@/components/partials/DatePickerCell'
import INSERT_ANALYSIS_RESULT from '@/graphql/mutations/insertAnalysisResult.gql'
import DELETE_ANALYSIS_RESULT from '@/graphql/mutations/deleteAnalysisResult.gql'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { format } from 'date-fns'
import { SharedMixin } from '@/helpers/displayValue'

export default {
    name: 'AnalysisResultTable',
    components: { DatePickerCell, EditableCell },
    mixins: [ FormRules, MessageDialog, SharedMixin ],
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        episode: {
            type: Object,
            default: () => {}
        },
        isEditable: {
            type: Boolean,
            default: true
        },
        isDeletable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            emptyText: 'No analysis result found',
            duration: 3000,
            type: '',
            message: '',
            itemsPerPage: 10,
            page: 1,
            tableDataArr: [],
            currentNewId: null,
            activeRowId: null,
            currentRow: null,
            footerProps: {
                'items-per-page-options': [10, 20, 30, 40]
            },
            headers: [
                // { text: 'No', align: 'left', value: 'order', class: 'order--header w-50' },
                { text: 'Date Taken', align: 'left', value: 'dateTaken', class: 'w-200 date--header' },
                { text: 'Reason', align: 'left', value: 'reason', class: 'time--header' },
                { text: 'Result Date', align: 'left', value: 'resultDate', class: 'w-200 type--header' },
                { text: 'Result', align: 'left', value: 'result', class: 'person--header' },
                { text: '', align: 'left', value: 'editRecord', fixed: true},
            ],
            sortBy: [],
            sortDesc: [true],
            refsToCheck: [
                // 'order',
                'dateTaken',
                'reason',
                'resultDate',
                'result'
            ],
            defaultAnalysisResult: {
                dateTaken: format(new Date(), 'yyyy-MM-dd'),
                reason: null,
                resultDate: null,
                result: null
            }
        }
    },
    mounted() {
        this.tableDataArr = [...this.tableData]
        if (!this.tableDataArr.length) this.addAnalysisResult()
    },
    methods: {
        rowClickHandler(obj, e) {
            this.currentRow = obj
            e.select(true)
        },
        addAnalysisResult() {
            // if we already have a new behaviour Incident, then we stop the function
            if (this.currentNewId) return
            console.log('adding new analysis result row')

            this.currentNewId = Math.floor(Math.random() * 1000000) + 5000000
            this.activeRowId = this.currentNewId
            const tempAnalysisResult = {
                analysisResultId: this.currentNewId,
                episodeId: this.episode.episodeId,
                userId: this.loggedInUser.userId,
                ...this.defaultAnalysisResult
            }
            this.tableDataArr.push(tempAnalysisResult)

            this.page = parseInt((this.tableDataArr.length - 1) / this.itemsPerPage) + 1
        },
        toggleEdit(val) {
            if(!this.isEditable) return false
            if (this.currentNewId) {
                this.showDiscardMessage(false)
                return false
            } else if (this.activeRowId && this.isActiveRowChanged()) {
                this.showDiscardMessage(true)
                return false
            }
            this.activeRowId = val
            this.activeRowObj = this.getActiveRow(this.tableDataArr)
        },
        showDiscardMessage(isEdit) {
            this.type = 'warning'
            this.message = `Please save/discard the ${isEdit ? 'editing' : 'new'} row before moving to other rows.`
            this.showMessage({duration: 1500})
        },
        isActiveRowChanged() {
            // const fields = ['order', 'goal', 'commenced', 'completed']
            const fields = ['goal', 'commenced', 'completed']
            const changedObj = this.getActiveRow(this.tableDataArr)
            if (!changedObj) return false

            return fields.filter(field => this.activeRowObj[field] != changedObj[field]).length > 0
        },
        getActiveRow(data) {
            const activeRow = data.find((item) => {
                return item.analysisResultId === this.activeRowId
            })
            return {...activeRow}
        },
        validateInputs(id) {
            const forms = this.refsToCheck.reduce((arr, curr) => {
                arr.push(this.$refs[`${curr}-${id}`].validate())
                return arr
            }, [])
            return Promise.all(forms)
        },
        saveHandler() {
            this.validateInputs(this.activeRowId).then(values => {
                let isSuccess = true
                values.forEach(value => { if(!value) isSuccess = false })
                if(isSuccess) {
                    // Post back to db
                    // this.mutationCall(this.getActiveRow(this.tableDataArr), false)

                    const data = this.getActiveRow(this.tableDataArr)
                    delete data.updated
                    const idx = this.tableDataArr.findIndex(t => t.analysisResultId === data.analysisResultId)

                    if (data && data.typename) delete data.typename
                    if (this.currentNewId && this.currentNewId === this.activeRowId) delete data.analysisResultId

                    delete data.active
                    const convertedData = snakecaseKeys(data, {deep: true})
                    this.$apollo.mutate({
                        mutation: INSERT_ANALYSIS_RESULT,
                        variables: {
                            analysisResult: convertedData
                        }
                    }).then(({ data: { analysisResult } }) => {
                        console.log('analysis result returned', analysisResult)
                        if (analysisResult) {
                            this.tableDataArr[idx] = {...camelcaseKeys(analysisResult, {deep: true})}
                        }

                        this.saving = false
                        this.message = 'Saved analysis result item'
                        this.type = 'success'

                        this.activeRowId = null
                        this.currentNewId = null
                        this.activeRowObj = null

                        this.$emit('analysis-result-mutated')
                    }).catch(error => {
                        this.message = 'Failed saving analysis result'
                        this.type = 'error'
                    }).finally(() => {
                        this.saving = false
                        data.active = true
                        this.showMessage({ duration: 5000 })
                    })
                }
            }).catch(err => {
                console.error('validation failed: ', err)
            })
        },
        filterTable(data) {
            return data.map(item => {
                let isActive = item.analysisResultId === this.activeRowId
                return {
                    ...item,
                    active: isActive
                }
            })
        },
        resetTableRow() {
            // if an existing row is being edited
            if(!this.currentNewId) {
                // update the data on the row to the original values stored in activeRowObj
                this.tableDataArr = this.tableDataArr.map(item => {
                    if (item.analysisResultId === this.activeRowId) {
                        return this.activeRowObj
                    }
                    return item
                })
            } else {
                // if its a new staff member, cancel the add by filtering them from the table arr
                this.tableDataArr = this.tableDataArr.filter(item => {
                    if (item.analysisResultId !== this.activeRowId) return item
                })
            }
        },
        deleteHandler(item) {
            this.clearRowSelection()
            this.tableDataArr = this.tableDataArr.filter(td => {
                if (td.analysisResultId !== item.analysisResultId) return td
            })
            this.$apollo.mutate({
                mutation: DELETE_ANALYSIS_RESULT,
                variables: {
                    analysisResultId: item.analysisResultId
                }
            }).then(({ data: { analysisResult } }) => {
                console.log('analysis result returned', analysisResult)

                this.saving = false
                this.message = 'Deleted analysis result'
                this.type = 'success'

                this.activeRowId = null
                this.currentNewId = null
                this.activeRowObj = null

                this.$emit('analysis-result-mutated')
            }).catch(error => {
                this.message = 'Failed deleting analysis result'
                this.type = 'error'
            }).finally(() => {
                this.saving = false
                this.showMessage({ duration: 5000 })
            })
        },
        cancelHandler() {
            this.resetTableRow()
            this.clearRowSelection()
        },
        clearRowSelection() {
            this.activeRowId = null
            this.currentNewId = null
            this.activeRowObj = null
        }
    },
    computed: {
        ...mapState({
            loggedInUser: state => state.app.loggedInUser,
            staticChoices: state => state.app.staticChoices
        }),
    },
    watch: {
        tableData() {
            this.clearRowSelection()
            this.tableDataArr = [...this.tableData]
            if (!this.tableDataArr.length) this.addAnalysisResult()
        },
        activeRowId() {
            this.tableDataArr = this.filterTable(this.tableDataArr)
        }
    }
}
</script>
